import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgxPhoneSelectDirective} from './ngx-phone-select';
import 'intl-tel-input';

@NgModule({
  declarations: [NgxPhoneSelectDirective],
  imports: [CommonModule],
  providers: [
    NgxPhoneSelectDirective
  ],
  exports: [NgxPhoneSelectDirective]
})
export class NgxPhoneSelectModule {
}
