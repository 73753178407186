import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NewsletterFormService} from './newsletter-form.service';
import {CookieService} from 'ngx-cookie-service';
import isNil from 'lodash-es/isNil';
import isUndefined from 'lodash-es/isUndefined';
import isNull from 'lodash-es/isNull';
import isEmpty from 'lodash-es/isEmpty';
import {isPlatformBrowser} from '@angular/common';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {setTimeout$} from "../../../services/utils";

@Component({
  selector: 'app-newsletter-form',
  templateUrl: './newsletter-form.component.html',
  styleUrls: ['./newsletter-form.component.scss'],
  providers: [NewsletterFormService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsletterFormComponent implements OnInit {
  email: string;
  hotDealsForm: FormGroup;
  formValid: boolean;
  formSent: boolean;
  formSending = false;
  alreadySignedUp: boolean;
  private _success = new Subject<string>();

  staticAlertClosed = false;
  successMessage: string;
  showNewsletter = false;
  constructor(private signUpService: NewsletterFormService,
              private cookieService: CookieService,
              private formBuilder: FormBuilder,
              private changeDetection: ChangeDetectorRef,
              @Inject(PLATFORM_ID) private platformId: Object) {}

  ngOnInit() {
    this.formValid = true;
    this.formSent = false;
    this.alreadySignedUp = false;
    this.hotDealsForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])]
    });
    if (isPlatformBrowser(this.platformId)) {
      if (!this.isEmptyLocal(this.cookieService.get('WF_HOT_DEALS_SENT'))) {
        this.alreadySignedUp = true;
      }
      setTimeout$(() => this.staticAlertClosed = true, 20000);
    }

    this._success.subscribe((message) => this.successMessage = message);
    this._success.pipe(debounceTime(5000)).subscribe(() => this.successMessage = null);
  }

  public sendHotDeal() {
    this.formValid = this.hotDealsForm.valid;
    if (this.formValid) {
      this.formSending = true;

      this.signUpService.sendEmail(this.email).subscribe(
        response => {
          this.formSending = false;

          if (!isNil(response.data.error)) {
            this.alertMessage(response.data.error);
          } else {
            this.cookieService.set('WF_HOT_DEALS_SENT', this.email);
            this.formSent = true;
            this.changeDetection.detectChanges();

            setTimeout$(() => {
              this.alreadySignedUp =  true;
              this.changeDetection.detectChanges();
            }, 3000);

          }
        },
        error => {
          this.alertMessage('Can\'t process your request now. Please try later.');
          this.formSending = false;
          this.changeDetection.detectChanges();
        });
    }
  }

  private isEmptyLocal(fieldValue): boolean {
    return isEmpty(fieldValue) || isUndefined(fieldValue) || isNull(fieldValue);
  }

  private alertMessage(message) {
    this._success.next(message);
  }
}
