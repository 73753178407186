import { Injectable } from '@angular/core';

import { FlightMobileInterface, FlightOptions } from '../model/flight-mobile.interface';

@Injectable()
export class SearchboxFlightsMobileService {
  private flightQuoteData: FlightMobileInterface = {
    queryCruiseParams: {
      tripType: null,
      cabin: null,
      passengers: null
    }
  };

  constructor() {
  }

  public setFlightOptions(flightOptions: FlightOptions): void {
    this.flightQuoteData.queryCruiseParams = {...flightOptions};
  }

  public getFlightQuoteData(): FlightMobileInterface {
    return this.flightQuoteData;
  }
}
