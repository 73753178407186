/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navigation-rounds.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./navigation-rounds.component";
var styles_NavigationRoundsComponent = [i0.styles];
var RenderType_NavigationRoundsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavigationRoundsComponent, data: {} });
export { RenderType_NavigationRoundsComponent as RenderType_NavigationRoundsComponent };
function View_NavigationRoundsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "item"]], [[2, "active", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.context.index + 1) === _co.activeItem); _ck(_v, 0, 0, currVal_0); }); }
export function View_NavigationRoundsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "navigation-rounds"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_NavigationRoundsComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpad(3, 3)], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, 1, 2, 3); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_NavigationRoundsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navigation-rounds", [], null, null, null, View_NavigationRoundsComponent_0, RenderType_NavigationRoundsComponent)), i1.ɵdid(1, 114688, null, 0, i3.NavigationRoundsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavigationRoundsComponentNgFactory = i1.ɵccf("app-navigation-rounds", i3.NavigationRoundsComponent, View_NavigationRoundsComponent_Host_0, { activeItem: "activeItem" }, {}, []);
export { NavigationRoundsComponentNgFactory as NavigationRoundsComponentNgFactory };
