<!-- Page Content Service Block-->
<section id="service">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 service-text"  [ngClass]="{'text-left':business}">
        <h2 class="mt-4">Real travel experts, genuine savings</h2>
        <p>We combine technology and human expertise to provide low fares and superior service.</p>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-4 my-2 my-md-4">
        <div class="service-block">
          <img width="50" height="50" class="service-block-icon" [lazyLoad]="cdnPath + '/assets/img/icons/piggy-bank.svg'" alt="">
          <div class="service-block-body">
            <h1>Maximum Savings</h1>
            <p>Major discounts on international first and business class flights, hotels, and cruises.</p>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-4 my-2 my-md-4">
        <div class="service-block">
          <img width="50" height="50"  class="service-block-icon" [lazyLoad]="cdnPath + '/assets/img/icons/operator.svg'" alt="">
          <div class="service-block-body">
            <h1>Superior Service</h1>
            <p>Expert travel manager providing support in real time before, during, and after your trip.</p>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-4 my-2 my-md-4">
        <div class="service-block">
          <img width="50" height="50"  class="service-block-icon" [lazyLoad]="cdnPath + '/assets/img/icons/shuffle.svg'" alt="">
          <div class="service-block-body">
            <h1>Simple Amendments</h1>
            <p>Your travel manager handles all the legwork, never wait on hold again.</p>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-4 my-2 my-md-4">
        <div class="service-block">
          <img width="50" height="50" class="service-block-icon" [lazyLoad]="cdnPath + '/assets/img/icons/tag.svg'" alt="">
          <div class="service-block-body">
            <h1>Discounts & Upgrades</h1>
            <p>We proactively maximize all discounts and loyalty benefits for every trip.</p>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-4 my-2 my-md-4">
        <div class="service-block">
          <img width="50" height="50"  class="service-block-icon" [lazyLoad]="cdnPath + '/assets/img/icons/monitor.svg'" alt="">
          <div class="service-block-body">
            <h1>Phone, Email, & Text</h1>
            <p>Contact your travel manger anytime by phone, email, or text message.</p>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-4 my-2 my-md-4">
        <div class="service-block">
          <img width="50" height="50"  class="service-block-icon" [lazyLoad]="cdnPath + '/assets/img/icons/handshake.svg'" alt="">
          <div class="service-block-body">
            <h1>Trust & Credibility</h1>
            <p>Founded in 2006 with excellent ratings on Yelp, Google, & BBB with over 15 million happy customers.</p>
          </div>
        </div>
      </div>

    </div>
    <!-- /.row -->

  </div>
  <!-- /.container -->
</section>
