import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {User} from '../../../model/user';
import {Review} from '../../../model/review';
import {RestResponse} from '../../../model/rest-response';
import {environment} from "../../../../environments/environment";

@Injectable()
export class WriteReviewService {

  constructor(private http: HttpClient) {
  }

  getReviewUsers(): Observable<User[]> {
    return this.http.get<User[]>(environment.serverUrl + '/reviews/get-review-users');
  }

  submitReview(review: Review): Observable<RestResponse> {
    return this.http.post<RestResponse>(environment.serverUrl + '/reviews/save-review', review);
  }

}
