export function transformToRoundBrackets(row: string): string {
 return row.replace(/\[/g, '(').replace(/\]/g, ')');
}

export function transformToSquareBrackets(row: string): string {
  return row.replace(/\(/g, '[').replace(/\)/g, ']');
}

export function removeBrackets(row: string): string {
  if (row.indexOf('(') >= 0) {
    return row.slice(0, row.indexOf('(') - 1);
  }
  return row;
}

export function extractAirportCodeFromBrackets(row: string): string {
  if (row.indexOf('(') >= 0) {
    return row.slice(row.indexOf('(') + 1, -1);
  }
  return row;
}
