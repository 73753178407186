import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';

import { DateMobileInterface } from '../../../model/date-mobile.interface';
import { formatDateString } from '../../../../shared/util/date-util';

@Component({
  selector: 'app-round-trip-date-dialog',
  templateUrl: './round-trip-date-dialog.component.html',
  styleUrls: ['./round-trip-date-dialog.component.scss']
})
export class RoundTripDateDialogComponent implements OnInit {
  public months: Array<DateMobileInterface> = [];
  public isDepart: boolean;
  public selectedValue: NgbDateStruct = { year: null, month: null, day: null };
  public departControl: FormControl = new FormControl(null);
  public departureControl: FormControl = new FormControl(null);
  public startDay: any = { year: null, month: null, day: null };
  public endDay: any = { year: null, month: null, day: null };

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  public ngOnInit(): void {
    this.generateCalendar();
  }

  public generateCalendar(): void {
    const currentDate: Date = new Date();
    const numberOfMonths = 12;

    for (let i: number = 0; i < numberOfMonths; i++) {
      const firstDayOfMonth: Date = new Date(currentDate.getFullYear(), currentDate.getMonth() + i, 1);
      const lastDayOfMonth: Date = new Date(currentDate.getFullYear(), currentDate.getMonth() + i + 1, 0);

      const daysInMonth = _.range(1, lastDayOfMonth.getDate() + 1);

      const monthName: string = firstDayOfMonth.toLocaleString('en-US', { month: 'long' });
      const formattedMonth: string = monthName.charAt(0).toUpperCase() + monthName.slice(1);

      this.months.push({
        month: formattedMonth,
        numericMonth: firstDayOfMonth.getMonth() + 1,
        year: firstDayOfMonth.getFullYear(),
        days: daysInMonth,
        firstDayOfWeek: firstDayOfMonth.getDay()
      });
    }
  }

  public selectDate(year: number, month: number, day: number): void {
    if (!this.isOldDate(year, month, day)) {
      if (!this.startDay.year) {
        this.startDay = {year, month, day};
      } else if (!this.endDay.year) {
        if (new Date(year, month - 1, day, 0, 0, 0, 0) <
            new Date(this.startDay.year, this.startDay.month - 1, this.startDay.day, 0, 0, 0, 0)) {
          this.startDay = {year, month, day};
        } else {
          this.endDay = {year, month, day};
        }
      } else {
        this.startDay = {year, month, day};
        this.endDay = {year: null, month: null, day: null};
      }
    }
  }

  public getFieldsPlaceholder(data: NgbDateStruct): string {
    if (data.year) {
      return formatDateString(new Date(data.year, data.month - 1, data.day).toString(), 'ddd, MMM DD');
    } else {
      return  '-';
    }
  }

  public isActiveDate(year: number, month: number, day: number): boolean {
    const { year: activeYear, month: activeMonth, day: activeDay } = this.startDay;
    return year === activeYear && month === activeMonth && day === activeDay;
  }

  public isActiveSecondDate(year: number, month: number, day: number): boolean {
    const { year: activeYear, month: activeMonth, day: activeDay } = this.endDay;
    return year === activeYear && month === activeMonth && day === activeDay;
  }

  public isDateInRange(year: number, month: number, day: number): boolean {
    if (this.startDay.year && this.endDay.year) {
      const start: Date = new Date(this.startDay.year, this.startDay.month - 1, this.startDay.day);
      const end: Date = new Date(this.endDay.year, this.endDay.month - 1, this.endDay.day);
      const currentDate: Date = new Date(year, month - 1, day);
        return start < currentDate && currentDate < end;
    }
    return false;
  }

  public isOldDate(year: number, month: number, day: number): boolean {
    const givenDate: Date = new Date(year, month - 1, day);
    const currentDate: Date = new Date();

    givenDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    return currentDate > givenDate;
  }

  public isDisableSaveButton(): boolean {
    return !(this.startDay.year && this.endDay.year);
  }

  public saveAndClose(): void {
    this.closeDialog({
      startDay: this.startDay,
      endDay: this.endDay
    });
  }

  public closeDialog(value: { startDay: NgbDateStruct, endDay: NgbDateStruct } = null): void {
    this.activeModal.close(value);
  }

  public moveBack(): void {
    this.activeModal.dismiss();
  }
}
