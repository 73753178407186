export const getElementById = (elementId: string): HTMLElement => {
  return document.getElementById(elementId);
};

export const getElementsByClass = (elementClass: string): HTMLCollectionOf<Element> => {
  return document.getElementsByClassName(elementClass);
};

export const fitCountrySelect = (): void => {
  const phoneElem: HTMLElement = <HTMLElement> getElementsByClass('intl-tel-input')[0];
  if (phoneElem) {
    const width = phoneElem.offsetWidth;
    const countryListElem: HTMLElement = <HTMLElement> getElementsByClass('country-list')[0];
    if (countryListElem) {
      countryListElem.style.width = width + 'px';
    }
  }
};
