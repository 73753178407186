<section id="deals">
    <div class="container py-4 py-md-5">
      <div class="row">
  
        <div class="col-12">
            <h2 class="mb-3">Business Class Flight Deals</h2>
        </div>
  
        <div class="col-12 px-1">
  
          <!-- Desctop -->
          <div id="carouselDealsDesctop" class="carousel slide d-none d-lg-block" data-ride="carousel">
            <div class="carousel-inner">
            
              <div class="carousel-item active">
                <div class="cards-wrapper">
                
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-rome.jpg" alt="Rome"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>Rome</span>
                        </div>
                        <div class="economy">
                          <span class="save">33% OFF</span>
                          <p>
                            <span class="price">$2,121</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>New York</span><span>$2,121</span></li>
                          <li><span><i>✈️</i>Miami</span><span>$2,355</span></li>
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,203</span></li>
                          <li><span><i>✈️</i>Chicago</span><span>$2,265</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
      
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-london.jpg" alt="London"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>London</span>
                        </div>
                        <div class="economy">
                          <span class="save">40% OFF</span>
                          <p>
                            <span class="price">$2,129</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>Chicago</span><span>$2,296</span></li>
                          <li><span><i>✈️</i>New York</span><span>$2,129</span></li>
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,259</span></li>
                          <li><span><i>✈️</i>Miami</span><span>$2,362</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
      
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-paris.jpg" alt="Paris"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>Paris</span>
                        </div>
                        <div class="economy">
                          <span class="save">23% OFF</span>
                          <p>
                            <span class="price">$2,150</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,220</span></li>
                          <li><span><i>✈️</i>Chicago</span><span>$2,287</span></li>
                          <li><span><i>✈️</i>New York</span><span>$2,150</span></li>
                          <li><span><i>✈️</i>Miami</span><span>$2,387</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                
                </div>
              </div>
      
              <div class="carousel-item">
                <div class="cards-wrapper">
               
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-amsterdam.jpg" alt="Amsterdam"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>Amsterdam</span>
                        </div>
                        <div class="economy">
                          <span class="save">25% OFF</span>
                          <p>
                            <span class="price">$2,173</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,243</span></li>
                          <li><span><i>✈️</i>New York</span><span>$2,173</span></li>
                          <li><span><i>✈️</i>Miami</span><span>$2,352</span></li>
                          <li><span><i>✈️</i>Chicago</span><span>$2,279</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
      
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-madrid.jpg" alt="Madrid"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>Madrid</span>
                        </div>
                        <div class="economy">
                          <span class="save">27% OFF</span>
                          <p>
                            <span class="price">$2,162</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>Miami</span><span>$2,377</span></li>
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,273</span></li>
                          <li><span><i>✈️</i>New York</span><span>$2,162</span></li>
                          <li><span><i>✈️</i>Chicago</span><span>$2,296</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
      
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-singapore.jpg" alt="Singapore"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>Singapore</span>
                        </div>
                        <div class="economy">
                          <span class="save">28% OFF</span>
                          <p>
                            <span class="price">$3,598</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>Miami</span><span>$2,428</span></li>
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,605</span></li>
                          <li><span><i>✈️</i>New York</span><span>$2,769</span></li>
                          <li><span><i>✈️</i>Chicago</span><span>$2,470</span></li> 
                        </ul>
                      </div>
                    </div>
                  </div>
                
                </div>
              </div>
  
              <div class="carousel-item">
                <div class="cards-wrapper">
               
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-athens.jpg" alt="Athens"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>Athens</span>
                        </div>
                        <div class="economy">
                          <span class="save">32% OFF</span>
                          <p>
                            <span class="price">$2,185</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>New York</span><span>$2,185</span></li>
                          <li><span><i>✈️</i>Chicago</span><span>$2,293</span></li>
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,261</span></li>
                          <li><span><i>✈️</i>Miami</span><span>$2,350</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
      
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-hong-kong.jpg" alt="Hong Kong"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>Hong Kong</span>
                        </div>
                        <div class="economy">
                          <span class="save">40% OFF</span>
                          <p>
                            <span class="price">$3,508</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>Chicago</span><span>$2,767</span></li>
                          <li><span><i>✈️</i>New York</span><span>$2,550</span></li>
                          <li><span><i>✈️</i>Miami</span><span>$2,467</span></li>
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,327</span></li> 
                        </ul>
                      </div>
                    </div>
                  </div>
      
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-zurich.jpg" alt="Zurich"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>Zurich</span>
                        </div>
                        <div class="economy">
                          <span class="save">31% OFF</span>
                          <p>
                            <span class="price">$2,245</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,311</span></li>
                          <li><span><i>✈️</i>Miami</span><span>$2,396</span></li>
                          <li><span><i>✈️</i>Chicago</span><span>$2,347</span></li>
                          <li><span><i>✈️</i>New York</span><span>$2,245</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                
                </div>
              </div>
  
              <div class="carousel-item">
                <div class="cards-wrapper">
               
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-beijing.jpg" alt="Beijing"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>Beijing</span>
                        </div>
                        <div class="economy">
                          <span class="save">37% OFF</span>
                          <p>
                            <span class="price">$3,508</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>New York</span><span>$2,489</span></li>
                          <li><span><i>✈️</i>Chicago</span><span>$2,500</span></li> 
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,378</span></li> 
                          <li><span><i>✈️</i>Miami</span><span>$2,790</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
      
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-dubai.jpg" alt="Dubai"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>Dubai</span>
                        </div>
                        <div class="economy">
                          <span class="save">20% OFF</span>
                          <p>
                            <span class="price">$2,875</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>Miami</span><span>$2,308</span></li>
                          <li><span><i>✈️</i>Chicago</span><span>$2,419</span></li> 
                          <li><span><i>✈️</i>New York</span><span>$2,590</span></li>
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,629</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
      
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-tokyo.jpg" alt="Tokyo"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>Tokyo</span>
                        </div>
                        <div class="economy">
                          <span class="save">36% OFF</span>
                          <p>
                            <span class="price">$3,472</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,492</span></li> 
                          <li><span><i>✈️</i>New York</span><span>$2,370</span></li>
                          <li><span><i>✈️</i>Chicago</span><span>$2,660</span></li> 
                          <li><span><i>✈️</i>Miami</span><span>$2,739</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                
                </div>
              </div>
      
            </div>
      
            <a class="carousel-control-prev" href="#carouselDealsDesctop" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselDealsDesctop" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
  
          <!-- Tablet -->
          <div id="carouselDealsTablet" class="carousel slide  d-none d-md-block d-lg-none" data-ride="carousel">
            <div class="carousel-inner">
            
              <div class="carousel-item active">
                <div class="cards-wrapper">
                
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-rome.jpg" alt="Rome"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>Rome</span>
                        </div>
                        <div class="economy">
                          <span class="save">33% OFF</span>
                          <p>
                            <span class="price">$2,121</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>New York</span><span>$2,121</span></li>
                          <li><span><i>✈️</i>Miami</span><span>$2,355</span></li>
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,203</span></li>
                          <li><span><i>✈️</i>Chicago</span><span>$2,265</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
      
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-london.jpg" alt="London"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>London</span>
                        </div>
                        <div class="economy">
                          <span class="save">40% OFF</span>
                          <p>
                            <span class="price">$2,129</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>Chicago</span><span>$2,296</span></li>
                          <li><span><i>✈️</i>New York</span><span>$2,129</span></li>
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,259</span></li>
                          <li><span><i>✈️</i>Miami</span><span>$2,362</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                
                </div>
              </div>
  
              <div class="carousel-item">
                <div class="cards-wrapper">
  
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-paris.jpg" alt="Paris"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>Paris</span>
                        </div>
                        <div class="economy">
                          <span class="save">23% OFF</span>
                          <p>
                            <span class="price">$2,150</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,220</span></li>
                          <li><span><i>✈️</i>Chicago</span><span>$2,287</span></li>
                          <li><span><i>✈️</i>New York</span><span>$2,150</span></li>
                          <li><span><i>✈️</i>Miami</span><span>$2,387</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
  
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-amsterdam.jpg" alt="Amsterdam"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>Amsterdam</span>
                        </div>
                        <div class="economy">
                          <span class="save">25% OFF</span>
                          <p>
                            <span class="price">$2,173</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,243</span></li>
                          <li><span><i>✈️</i>New York</span><span>$2,173</span></li>
                          <li><span><i>✈️</i>Miami</span><span>$2,352</span></li>
                          <li><span><i>✈️</i>Chicago</span><span>$2,279</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
  
                </div>
              </div>
  
              <div class="carousel-item">
                <div class="cards-wrapper">
      
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-madrid.jpg" alt="Madrid"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>Madrid</span>
                        </div>
                        <div class="economy">
                          <span class="save">27% OFF</span>
                          <p>
                            <span class="price">$2,162</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>Miami</span><span>$2,377</span></li>
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,273</span></li>
                          <li><span><i>✈️</i>New York</span><span>$2,162</span></li>
                          <li><span><i>✈️</i>Chicago</span><span>$2,296</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
      
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-singapore.jpg" alt="Singapore"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>Singapore</span>
                        </div>
                        <div class="economy">
                          <span class="save">28% OFF</span>
                          <p>
                            <span class="price">$3,598</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>Miami</span><span>$2,428</span></li>
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,605</span></li>
                          <li><span><i>✈️</i>New York</span><span>$2,769</span></li>
                          <li><span><i>✈️</i>Chicago</span><span>$2,470</span></li> 
                        </ul>
                      </div>
                    </div>
                  </div>
                
                </div>
              </div>
  
              <div class="carousel-item">
                <div class="cards-wrapper">
  
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-athens.jpg" alt="Athens"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>Athens</span>
                        </div>
                        <div class="economy">
                          <span class="save">32% OFF</span>
                          <p>
                            <span class="price">$2,185</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>New York</span><span>$2,185</span></li>
                          <li><span><i>✈️</i>Chicago</span><span>$2,293</span></li>
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,261</span></li>
                          <li><span><i>✈️</i>Miami</span><span>$2,350</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
  
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-hong-kong.jpg" alt="Hong Kong"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>Hong Kong</span>
                        </div>
                        <div class="economy">
                          <span class="save">40% OFF</span>
                          <p>
                            <span class="price">$3,508</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>Chicago</span><span>$2,767</span></li>
                          <li><span><i>✈️</i>New York</span><span>$2,550</span></li>
                          <li><span><i>✈️</i>Miami</span><span>$2,467</span></li>
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,327</span></li> 
                        </ul>
                      </div>
                    </div>
                  </div>
  
                </div>
              </div>
  
              <div class="carousel-item">
                <div class="cards-wrapper">
               
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-zurich.jpg" alt="Zurich"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>Zurich</span>
                        </div>
                        <div class="economy">
                          <span class="save">31% OFF</span>
                          <p>
                            <span class="price">$2,245</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,311</span></li>
                          <li><span><i>✈️</i>Miami</span><span>$2,396</span></li>
                          <li><span><i>✈️</i>Chicago</span><span>$2,347</span></li>
                          <li><span><i>✈️</i>New York</span><span>$2,245</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
  
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-beijing.jpg" alt="Beijing"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>Beijing</span>
                        </div>
                        <div class="economy">
                          <span class="save">37% OFF</span>
                          <p>
                            <span class="price">$3,508</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>New York</span><span>$2,489</span></li>
                          <li><span><i>✈️</i>Chicago</span><span>$2,500</span></li> 
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,378</span></li> 
                          <li><span><i>✈️</i>Miami</span><span>$2,790</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                
                </div>
              </div>
  
              <div class="carousel-item">
                <div class="cards-wrapper">
  
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-dubai.jpg" alt="Dubai"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>Dubai</span>
                        </div>
                        <div class="economy">
                          <span class="save">20% OFF</span>
                          <p>
                            <span class="price">$2,875</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>Miami</span><span>$2,308</span></li>
                          <li><span><i>✈️</i>Chicago</span><span>$2,419</span></li> 
                          <li><span><i>✈️</i>New York</span><span>$2,590</span></li>
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,629</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
      
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-tokyo.jpg" alt="Tokyo"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>Tokyo</span>
                        </div>
                        <div class="economy">
                          <span class="save">36% OFF</span>
                          <p>
                            <span class="price">$3,472</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,492</span></li> 
                          <li><span><i>✈️</i>New York</span><span>$2,370</span></li>
                          <li><span><i>✈️</i>Chicago</span><span>$2,660</span></li> 
                          <li><span><i>✈️</i>Miami</span><span>$2,739</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                
                </div>
              </div>
      
            </div>
  
            <!-- Mob -->
      
            <a class="carousel-control-prev" href="#carouselDealsTablet" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselDealsTablet" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
  
          <!-- Mob -->
          <div id="carouselDealsMob" class="carousel slide d-sm-block d-md-none" data-ride="carousel">
            <div class="carousel-inner">
            
              <div class="carousel-item active">
                <div class="cards-wrapper">
                
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-rome.jpg" alt="Rome"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>Rome</span>
                        </div>
                        <div class="economy">
                          <span class="save">33% OFF</span>
                          <p>
                            <span class="price">$2,121</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>New York</span><span>$2,121</span></li>
                          <li><span><i>✈️</i>Miami</span><span>$2,355</span></li>
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,203</span></li>
                          <li><span><i>✈️</i>Chicago</span><span>$2,265</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
  
                </div>
              </div>
  
              <div class="carousel-item">
                <div class="cards-wrapper">
  
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-london.jpg" alt="London"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>London</span>
                        </div>
                        <div class="economy">
                          <span class="save">40% OFF</span>
                          <p>
                            <span class="price">$2,129</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>Chicago</span><span>$2,296</span></li>
                          <li><span><i>✈️</i>New York</span><span>$2,129</span></li>
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,259</span></li>
                          <li><span><i>✈️</i>Miami</span><span>$2,362</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
  
                </div>
              </div>
  
              <div class="carousel-item">
                <div class="cards-wrapper">
  
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-paris.jpg" alt="Paris"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>Paris</span>
                        </div>
                        <div class="economy">
                          <span class="save">23% OFF</span>
                          <p>
                            <span class="price">$2,150</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,220</span></li>
                          <li><span><i>✈️</i>Chicago</span><span>$2,287</span></li>
                          <li><span><i>✈️</i>New York</span><span>$2,150</span></li>
                          <li><span><i>✈️</i>Miami</span><span>$2,387</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
  
                </div>
              </div>
  
              <div class="carousel-item">
                <div class="cards-wrapper">
  
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-amsterdam.jpg" alt="Amsterdam"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>Amsterdam</span>
                        </div>
                        <div class="economy">
                          <span class="save">25% OFF</span>
                          <p>
                            <span class="price">$2,173</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,243</span></li>
                          <li><span><i>✈️</i>New York</span><span>$2,173</span></li>
                          <li><span><i>✈️</i>Miami</span><span>$2,352</span></li>
                          <li><span><i>✈️</i>Chicago</span><span>$2,279</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
  
                </div>
              </div>
  
              <div class="carousel-item">
                <div class="cards-wrapper">
  
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-madrid.jpg" alt="Madrid"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>Madrid</span>
                        </div>
                        <div class="economy">
                          <span class="save">27% OFF</span>
                          <p>
                            <span class="price">$2,162</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>Miami</span><span>$2,377</span></li>
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,273</span></li>
                          <li><span><i>✈️</i>New York</span><span>$2,162</span></li>
                          <li><span><i>✈️</i>Chicago</span><span>$2,296</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
  
                </div>
              </div>
  
              <div class="carousel-item">
                <div class="cards-wrapper">
      
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-singapore.jpg" alt="Singapore"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>Singapore</span>
                        </div>
                        <div class="economy">
                          <span class="save">28% OFF</span>
                          <p>
                            <span class="price">$3,598</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>Miami</span><span>$2,428</span></li>
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,605</span></li>
                          <li><span><i>✈️</i>New York</span><span>$2,769</span></li>
                          <li><span><i>✈️</i>Chicago</span><span>$2,470</span></li> 
                        </ul>
                      </div>
                    </div>
                  </div>
                
                </div>
              </div>
  
              <div class="carousel-item">
                <div class="cards-wrapper">
  
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-athens.jpg" alt="Athens"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>Athens</span>
                        </div>
                        <div class="economy">
                          <span class="save">32% OFF</span>
                          <p>
                            <span class="price">$2,185</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>New York</span><span>$2,185</span></li>
                          <li><span><i>✈️</i>Chicago</span><span>$2,293</span></li>
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,261</span></li>
                          <li><span><i>✈️</i>Miami</span><span>$2,350</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
  
                </div>
              </div>
  
              <div class="carousel-item">
                <div class="cards-wrapper">
  
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-hong-kong.jpg" alt="Hong Kong"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>Hong Kong</span>
                        </div>
                        <div class="economy">
                          <span class="save">40% OFF</span>
                          <p>
                            <span class="price">$3,508</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>Chicago</span><span>$2,767</span></li>
                          <li><span><i>✈️</i>New York</span><span>$2,550</span></li>
                          <li><span><i>✈️</i>Miami</span><span>$2,467</span></li>
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,327</span></li> 
                        </ul>
                      </div>
                    </div>
                  </div>
  
                </div>
              </div>
  
              <div class="carousel-item">
                <div class="cards-wrapper">
  
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-zurich.jpg" alt="Zurich"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>Zurich</span>
                        </div>
                        <div class="economy">
                          <span class="save">31% OFF</span>
                          <p>
                            <span class="price">$2,245</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,311</span></li>
                          <li><span><i>✈️</i>Miami</span><span>$2,396</span></li>
                          <li><span><i>✈️</i>Chicago</span><span>$2,347</span></li>
                          <li><span><i>✈️</i>New York</span><span>$2,245</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
  
                </div>
              </div>
  
              <div class="carousel-item">
                <div class="cards-wrapper">
                
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-beijing.jpg" alt="Beijing"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>Beijing</span>
                        </div>
                        <div class="economy">
                          <span class="save">37% OFF</span>
                          <p>
                            <span class="price">$3,508</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>New York</span><span>$2,489</span></li>
                          <li><span><i>✈️</i>Chicago</span><span>$2,500</span></li> 
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,378</span></li> 
                          <li><span><i>✈️</i>Miami</span><span>$2,790</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                
                </div>
              </div>
  
              <div class="carousel-item">
                <div class="cards-wrapper">
  
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-dubai.jpg" alt="Dubai"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>Dubai</span>
                        </div>
                        <div class="economy">
                          <span class="save">20% OFF</span>
                          <p>
                            <span class="price">$2,875</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>Miami</span><span>$2,308</span></li>
                          <li><span><i>✈️</i>Chicago</span><span>$2,419</span></li> 
                          <li><span><i>✈️</i>New York</span><span>$2,590</span></li>
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,629</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
  
                </div>
              </div>
  
              <div class="carousel-item">
                <div class="cards-wrapper">
      
                  <div class="card" (click)="openCallMeDialog()">
                    <div class="feature">
                      <img class="" src="assets/img/business-card/business-class-flights-tokyo.jpg" alt="Tokyo"/>
                      <div class="feature__info">
                        <div class="direction">
                          <small>Business Class to</small>
                          <span>Tokyo</span>
                        </div>
                        <div class="economy">
                          <span class="save">36% OFF</span>
                          <p>
                            <span class="price">$3,472</span>
                          </p>
                        </div>
                      </div>
                      <div class="feature__list">
                        <ul>
                          <li><span><i>✈️</i>Los Angeles</span><span>$2,492</span></li> 
                          <li><span><i>✈️</i>New York</span><span>$2,370</span></li>
                          <li><span><i>✈️</i>Chicago</span><span>$2,660</span></li> 
                          <li><span><i>✈️</i>Miami</span><span>$2,739</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                
                </div>
              </div>
      
            </div>
  
            <a class="carousel-control-prev" href="#carouselDealsMob" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselDealsMob" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
  
        </div>
  
      </div>
    </div>
  </section>
