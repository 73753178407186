import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-navigation-rounds',
  templateUrl: './navigation-rounds.component.html',
  styleUrls: ['./navigation-rounds.component.scss']
})
export class NavigationRoundsComponent implements OnInit {
  @Input() public activeItem: number = 1;

  constructor() { }

  ngOnInit() {
  }

}
