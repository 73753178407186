import {NgModule} from '@angular/core';
import {RouterModule, Routes, UrlSerializer} from '@angular/router';
import {OrderUrlSerializer} from './serializers/OrderUrlSerializer';
import {HomeComponent} from './layout/pages/home/home.component';


const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'home', redirectTo: ''},
  {path: 'first-class', component: HomeComponent},
  {path: 'private',  loadChildren: () => import('../modules/luxary/luxary.module').then(mod => mod.LuxaryModule)},
  {path: 'about-us', loadChildren: () => import('../modules/faq/faq.module').then(mod => mod.FaqModule)},
  {path: 'contact-us', loadChildren: () => import('../modules/contact-us/contact-us.module').then(mod => mod.ContactUsModule)},
  {path: 'terms-and-conditions', loadChildren: () => import('../modules/terms-and-conditions/terms-and-conditions.module').then(mod => mod.TermsAndConditionsModule)},
  {path: 'terms-and-conditions-app', loadChildren: () => import('../modules/terms-and-conditions-app/terms-and-conditions-app.module').then(mod => mod.TermsAndConditionsAppModule)},
  {path: 'insurance-terms', loadChildren: () => import('../modules/insurance-terms/insurance-terms.module').then(mod => mod.InsuranceTermsModule)},
  {path: 'privacy-policy', loadChildren: () => import('../modules/privacy-policy/privacy-policy.module').then(mod => mod.PrivacyPolicyModule)},
  {path: 'corporate', loadChildren: () => import('../modules/corporate/corporate.module').then(mod => mod.CorporateModule)},
  // {path: 'corporate-private', loadChildren: () => import('../modules/corporate-private/corporate-private.module').then(mod => mod.CorporatePrivateModule)},
  {path: 'corporate-private', loadChildren: () => import('../modules/corporate-jets/corporate-jets.module').then(mod => mod.CorporateJetsModule)},
  {path: 'corporate-jets', loadChildren: () => import('../modules/corporate-jets/corporate-jets.module').then(mod => mod.CorporateJetsModule)},
  {path: 'reviews', loadChildren: () => import('../modules/reviews/reviews.module').then(mod => mod.ReviewsModule)},
  {path: 'our-team', loadChildren: () => import('../modules/our-team/our-team.module').then(mod => mod.OurTeamModule)},
  {path: 'our-team/:agent', loadChildren: () => import('../modules/agent/agent.module').then(mod => mod.AgentModule)},
  {path: 'business-class/:deal', loadChildren: () => import('../modules/offer/offer.module').then(mod => mod.OfferModule)},
  {path: 'business-class-flights-to/:deal', loadChildren: () => import('../modules/offer/offer.module').then(mod => mod.OfferModule)},
  {path: 'business-class-airlines/:deal', loadChildren: () => import('../modules/offer/offer.module').then(mod => mod.OfferModule)},
  {path: 'first-class/:deal', loadChildren: () => import('../modules/offer/offer.module').then(mod => mod.OfferModule)},
  {path: 'first-class-flights-to/:deal', loadChildren: () => import('../modules/offer/offer.module').then(mod => mod.OfferModule)},
  {path: 'first-class-airlines/:deal', loadChildren: () => import('../modules/offer/offer.module').then(mod => mod.OfferModule)},
  {path: 'all-business-class/:deal', loadChildren: () => import('../modules/flights/flights.module').then(mod => mod.FlightsModule)},
  {path: 'your-request', loadChildren: () => import('../modules/your-request/your-request.module').then(mod => mod.YourRequestModule)},
  {path: 'your-request/:type', loadChildren: () => import('../modules/your-request/your-request.module').then(mod => mod.YourRequestModule)},
  {path: 'privacy-app', loadChildren: () => import('../modules/privacy-app/privacy-app.module').then(mod => mod.PrivacyAppModule)},
  {path: 'app-page', loadChildren: () => import('../modules/mobile-app/mobile-app.module').then(mod => mod.MobileAppModule)},
  {path: 'booking-buddy', loadChildren: () => import('../modules/booking-buddy/booking-buddy.module').then(mod => mod.BookingBuddyModule)},
  {path: 'kayak', loadChildren: () => import('../modules/kayak/kayak.module').then(mod => mod.KayakModule)},
  {path: 'media-alpha', loadChildren: () => import('../modules/kayak/kayak.module').then(mod => mod.KayakModule)},
  {path: 'referral-program', loadChildren: () => import('../modules/referral-program/referral-program.module').then(mod => mod.ReferralProgramModule)},
  {path: 'unsubscribe/:customerId', loadChildren: () => import('../modules/unsubscribe/unsubscribe.module').then(mod => mod.UnsubscribeModule)},
  {path: 'hotels', loadChildren: () => import('../modules/hotels/hotels.module').then(mod => mod.HotelsModule)},
  {path: 'cruises', loadChildren: () => import('../modules/cruises/cruises.module').then(mod => mod.CruisesModule)},
  {path: '**', redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [{provide: UrlSerializer, useClass: OrderUrlSerializer}]
})
export class AppRoutingModule { }
