var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import eq from 'lodash-es/eq';
import isNil from 'lodash-es/isNil';
import { isPlatformBrowser } from '@angular/common';
import { ComponentBase } from '../ComponentBase';
import { filter } from 'rxjs/operators';
import * as moment from 'moment';
var FooterComponent = /** @class */ (function (_super) {
    __extends(FooterComponent, _super);
    function FooterComponent(router, platformId) {
        var _this = _super.call(this) || this;
        _this.router = router;
        _this.platformId = platformId;
        _this.fixBottom = false;
        return _this;
    }
    FooterComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (isPlatformBrowser(this.platformId)) {
            this.router.events
                .pipe(filter(function (event) { return event instanceof NavigationStart; }))
                .subscribe(function (event) {
                _this.fixBottom = eq(event.url, '/your-request');
            });
        }
    };
    Object.defineProperty(FooterComponent.prototype, "isBusinessLand", {
        get: function () {
            var businessLanding = this.landingClass === 'First' ? false : true;
            if (!this.firstClassLandingDetected) {
                this.firstClassLandingDetected = !businessLanding;
            }
            return businessLanding && !this.firstClassLandingDetected;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FooterComponent.prototype, "landingClass", {
        get: function () {
            return isNil(this.router) || isNil(this.router.url) || !this.router.url.includes('first-class') ? 'Business' : 'First';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FooterComponent.prototype, "year", {
        get: function () {
            return moment().format('YYYY'); // current year
        },
        enumerable: true,
        configurable: true
    });
    return FooterComponent;
}(ComponentBase));
export { FooterComponent };
