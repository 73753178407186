import { Pipe, PipeTransform } from '@angular/core';

import { transformToSquareBrackets } from '../model/transform-brackets';

@Pipe({
  name: 'transformFlightBrackets'
})
export class TransformFlightBracketsPipe implements PipeTransform {

  public transform(value: Array<string>, ...args: any[]): any {
    return value.map((element: string) => {
      return transformToSquareBrackets(element);
    });
  }

}
