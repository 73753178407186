<div class="modal show" tabindex="-1" role="dialog" aria-hidden="true" style="display: block;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <button type="button" class="btn-back" (click)="moveBack()">
                    <i class="icon-back"></i>
                </button>
                <h2 class="modal-title">Select travel dates</h2>
                <h2 class="modal-subtitle">2 of 3</h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeDialog()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <form>
                    <div [class.selected]="!this.startDay.year && !this.endDay.year"
                         class="form-input mr-1">
                        <label for="departure">Depart</label>
                        <input
                               type="text"
                               class="form-control"
                               [formControl]="departControl"
                               id="departure"
                               [placeholder]="getFieldsPlaceholder(this.startDay)"
                               readonly>
                        <span class="xIcon"></span>
                    </div>

                    <div [class.selected]="this.startDay.year && !this.endDay.year"
                         class="form-input ml-1">
                        <label for="destination">Return</label>
                        <input type="text"
                               class="form-control"
                               [formControl]="departureControl"
                               id="destination"
                               [placeholder]="getFieldsPlaceholder(this.endDay)"
                               readonly>
                        <span class="xIcon"></span>
                    </div>
                </form>
            </div>

            <div class="container px-0">
                <div class="row justify-content-center">
                    <div class="col-12 col-sm-10 col-md-8 col-lg-6">

                        <div class="calendars">
                            <div class="calendar" *ngFor="let month of months">
                                <div class="month-indicator">
                                    <time datetime="2024-02"> {{month.month}} {{month.year}} </time>
                                </div>
                                <div class="day-of-week">
                                    <div>S</div>
                                    <div>M</div>
                                    <div>T</div>
                                    <div>W</div>
                                    <div>T</div>
                                    <div>F</div>
                                    <div>S</div>
                                </div>
                                <div class="date-grid">
                                    <button [class.date-range]="isDateInRange(month.year, month.numericMonth, day)"
                                            [class.date-old]="isOldDate(month.year, month.numericMonth, day)"
                                            [class.date-active]="isActiveDate(month.year, month.numericMonth, day) ||
                                            isActiveSecondDate(month.year, month.numericMonth, day)"
                                            [style.grid-column]="i === 0 ? month.firstDayOfWeek + 1 : 0"
                                            *ngFor="let day of month.days; index as i">
                                        <time (click)="selectDate(month.year, month.numericMonth, day)">
                                            {{day}}
                                        </time>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button [disabled]="isDisableSaveButton()"
                        type="submit"
                        class="btn"
                        (click)="saveAndClose()">
                    Next
                </button>

                <app-navigation-rounds [activeItem]="2"></app-navigation-rounds>
            </div>

        </div>
    </div>
</div>
