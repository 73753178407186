<!--<app-trustpilot-box></app-trustpilot-box>-->
<!-- Navigation -->
<nav class="main-block navbar navbar-expand">
  <div class="container">
    <a class="navbar-brand-logo" routerLink="{{isBusinessLand? '/home' : '/first-class'}}">
      <img [lazyLoad]="cdnPath + '/assets/img/logo.svg'" alt="WholeSaleFlights.com"/>
    </a>
    <div class="ae">
<!--      <span></span><img [lazyLoad]=" cdnPath + '/assets/img/american-express.png'"/>-->
<!--      <p>Official Partner</p>-->
    </div>
    <div class="collapse navbar-collapse">

      <div class="phone-number-header" *ngIf="phoneVisible">
        <i class="call-icon d-none d-md-block"></i>
        <div class="phone-info d-none d-md-block">
          <span>Call For Exclusive Deals</span>
          <a href="tel:{{ phone }}">{{ phone | phone }}</a>
        </div>
        <a class="d-md-none visible-phone d-flex align-items-center" href="tel:{{ phone }}">
          <i class="call-icon"></i><b class="call-txt">Call Now</b>
        </a>
      </div>

      <div class="call-me-button">
        <!-- Button trigger modal -->
        <button type="button" class="btn" (click)="openCallMeDialog()">
          Call Back
        </button>
      </div>

      <input #navMenuCheckbox type="checkbox" id="nav-right-toggle" hidden />
      <label (click)="showMenu()"
        for="nav-right-toggle"
        class="nav-right-toggle"
        [ngClass]="{ 'right-align': !phoneVisible }"
      ></label>
      <div class="nav-right">
        <ul (click)="hideMenu()">
          <li
            itemscope="itemscope"
            itemtype="http://www.schema.org/SiteNavigationElement"
          >
            <a itemprop="url" routerLink="{{buildLink('flights', isBusinessLand)}}">
              <span itemprop="name">Flights</span>
            </a>
          </li>
          <li
            itemscope="itemscope"
            itemtype="http://www.schema.org/SiteNavigationElement"
          >
            <a itemprop="url" href="https://wholesale-flights.zenhotels.com/"
               target="_blank" rel="noopener noreferrer">
              <span itemprop="name">Hotels</span>
            </a>
          </li>
          <li
            itemscope="itemscope"
            itemtype="http://www.schema.org/SiteNavigationElement"
          >
            <a itemprop="url" routerLink="/cruises">
              <span itemprop="name">Cruises</span>
            </a>
          </li>
          <li
                  itemscope="itemscope"
                  itemtype="http://www.schema.org/SiteNavigationElement"
          >
            <a itemprop="url" routerLink="/corporate-private">
              <span itemprop="name">Private Jets</span>
            </a>
          </li>
          <li
            itemscope="itemscope"
            itemtype="http://www.schema.org/SiteNavigationElement"
          >
            <a itemprop="url" routerLink="/app-page">
              <span itemprop="name">App</span>
            </a>
          </li>
<!--          <li-->
<!--            itemscope="itemscope"-->
<!--            itemtype="http://www.schema.org/SiteNavigationElement"-->
<!--          >-->
<!--            <a itemprop="url" routerLink="reviews">-->
<!--              <span itemprop="name">Reviews</span>-->
<!--            </a>-->
<!--          </li>-->
          <li
            itemscope="itemscope"
            itemtype="http://www.schema.org/SiteNavigationElement"
          >
            <a itemprop="url" routerLink="corporate">
              <span itemprop="name">Corporate</span>
            </a>
          </li>
          <li
            itemscope="itemscope"
            itemtype="http://www.schema.org/SiteNavigationElement"
          >
            <a itemprop="url" routerLink="our-team">
              <span itemprop="name">Meet Our Team</span>
            </a>
          </li>
          <li
            itemscope="itemscope"
            itemtype="http://www.schema.org/SiteNavigationElement"
          >
            <a itemprop="url" routerLink="about-us">
              <span itemprop="name">About us</span>
            </a>
          </li>
          <!-- In blog it's necessary to change links in the header  -->
          <li
            itemscope="itemscope"
            itemtype="http://www.schema.org/SiteNavigationElement"
          >
            <a itemprop="url" href="https://blog.wholesale-flights.com/">
              <span itemprop="name">Blog</span>
            </a>
          </li>
          <li
            itemscope="itemscope"
            itemtype="http://www.schema.org/SiteNavigationElement"
          >
            <a itemprop="url" routerLink="contact-us">
              <span itemprop="name">Contact Us</span>
            </a>
          </li>
        </ul>
<!--        <a class="navbar-brand" href="/">-->
<!--          <img [lazyLoad]="cdnPath + '/assets/img/logo_mob.svg'"  alt="WholeSaleFlights.com"/>-->
<!--        </a>-->
      </div>
      <div class="mask-content"></div>
    </div>
  </div>
</nav>
