import * as i0 from "@angular/core";
var ConstantService = /** @class */ (function () {
    function ConstantService() {
        this._constants = {};
    }
    ConstantService.prototype.getConstant = function (key) {
        return this._constants[key];
    };
    ConstantService.prototype.setConstant = function (key, value) {
        this._constants[key] = value;
    };
    ConstantService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConstantService_Factory() { return new ConstantService(); }, token: ConstantService, providedIn: "root" });
    return ConstantService;
}());
export { ConstantService };
