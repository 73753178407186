import {Injectable} from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { CallMeDialogComponent } from './call-me-dialog.component';
import { DataService } from '../../../../shared/shared-data.service';

@Injectable()
export class CallMeService {

  constructor(private dataService: DataService,
              public dialog: MatDialog) {
  }

  public openCallMeDialog(): void {
    const config = new MatDialogConfig();
    config.position = {
      top: '2%',
    };
    config.panelClass = 'call-me-dialog';
    this.dataService.sendData({type: 'showModal', data: true});
    const dialogRef: MatDialogRef<CallMeDialogComponent> = this.dialog.open(CallMeDialogComponent, config);
    dialogRef.afterClosed().subscribe(result => {
      this.dataService.sendData({type: 'showModal', data: false});
    });
  }
}
