import {AfterViewInit, Directive, ElementRef, EventEmitter, Inject, Output, PLATFORM_ID} from '@angular/core';
import includes from 'lodash-es/includes';
import each from 'lodash-es/each';
import isUndefined from 'lodash-es/isUndefined';
import isNull from 'lodash-es/isNull';
import isEmpty from 'lodash-es/isEmpty';
import {isPlatformBrowser} from '@angular/common';

@Directive({
  selector: '[appClearIcon]',
})

export class ClearIconDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef, @Inject(PLATFORM_ID) private platformId: Object) {
  }

  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.getClearIcon().addEventListener('click', this.onClick.bind(this));
      this.elementRef.nativeElement.addEventListener('focus', this.onFocus.bind(this));
      this.elementRef.nativeElement.addEventListener('focusout', this.onFocusout.bind(this));
    }
  }

  onFocus() {
    const clearIcon = this.getClearIcon();
    this.setDisplay(clearIcon, 'inline');
  }

  onFocusout() {
    const clearIcon = this.getClearIcon();
    setTimeout(() => {
      this.setDisplay(clearIcon, 'none');
    }, 200);
  }

  onClick() {
    this.elementRef.nativeElement.value = '';
    this.ngModelChange.emit('');
    const clearIcon = this.getClearIcon();
    this.setDisplay(clearIcon, 'none');
  }

  private getClearIcon() {
    let clear;
    let parentNode = this.elementRef.nativeElement.parentNode;
    if (includes(parentNode.classList, 'intl-tel-input')) {
      parentNode = parentNode.parentNode;
    }
    each(parentNode.childNodes, (node: HTMLElement) => {
      if (!isNull(node.classList) && !isUndefined(node.classList) && !isEmpty(node.classList) && node.classList.contains('xIcon')) {
        clear = node;
      }
    });
    return clear;
  }

  private setDisplay(elem, value) {
    if (!(document.activeElement === this.elementRef.nativeElement && value === 'none')) {
      elem.style.display = value;
    }
  }
}
