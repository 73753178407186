import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from '../../app/pipes/safe-html-pipe';
import { SafeUrlPipe } from '../../app/pipes/safe-url.pipe';
import { ServiceComponent } from '../../app/layout/templates/service/service.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { OurServicesComponent } from '../../app/layout/templates/our-services/our-services.component';
import { YelpReviewsComponent } from '../../app/layout/templates/yelp-reviews/yelp-reviews.component';
import { ScriptInlineComponent } from '../../app/layout/templates/script-inline/script-inline.component';
import { PhonePipe } from '../../app/pipes/phone.pipe';
import { TrustpilotBoxComponent } from '../../app/layout/templates/trustpilot-box/trustpilot-box.component';
import { TrustpilotGridComponent } from '../../app/layout/templates/trustpilot-grid/trustpilot-grid.component';
import { NewsletterFormComponent } from '../../app/layout/templates/newsletter-form/newsletter-form.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MobileAppBlockComponent } from '../../app/layout/templates/mobile-app-block/mobile-app-block.component';
import { TrendingDealsComponent } from '../../app/layout/templates/trending-deals/trending-deals.component';
import { RouterModule } from '@angular/router';
import { SearchboxComponent } from '../../app/layout/templates/searchbox/searchbox.component';
import { MatAutocompleteModule } from '@angular/material';
import { NgxPhoneSelectModule } from '../ngx-phone-select/ngx-phone-select.module';
import { TextMaskModule } from 'angular2-text-mask';
import { DeferLoadModule } from '@trademe/ng-defer-load';
import { ClickOutsideDirective } from '../../app/directives/click-outside.directive';
import { ServiceLuxaryComponent } from '../../app/layout/templates/service-luxary/service-luxary.component';
import {
  OurServicesLuxaryComponent
} from '../../app/layout/templates/our-services-luxary/our-services-luxary.component';
import { InfoLuxaryComponent } from '../../app/layout/templates/info-luxary/info-luxary.component';
import {
  SearchboxNewGenerationComponent
} from '../../app/layout/templates/searchbox-new-generation/searchbox-new-generation.component';
import { InfoComponent } from '../../app/layout/templates/info/info.component';
import { AirlinesBlockComponent } from '../../app/layout/templates/airlines-block/airlines-block.component';
import { SearchboxHotelsComponent } from '../../app/layout/templates/searchbox-hotels/searchbox-hotels.component';
import { SearchboxCruisesComponent } from '../../app/layout/templates/searchbox-cruises/searchbox-cruises.component';
import { SectionOneComponent } from '../../app/layout/templates/section-one/section-one.component';
import { SectionTwoComponent } from '../../app/layout/templates/section-two/section-two.component';
import { SectionThreeComponent } from '../../app/layout/templates/section-three/section-three.component';
import { SectionFourComponent } from '../../app/layout/templates/section-four/section-four.component';
import { SectionFiveComponent } from '../../app/layout/templates/section-five/section-five.component';
import { SectionSixComponent } from '../../app/layout/templates/section-six/section-six.component';
import { SectionSevenComponent } from '../../app/layout/templates/section-seven/section-seven.component';
import { CallMeService } from '../../app/layout/modal/call-me-dialog/call-me.service';
import { CallMeHttpService } from '../../app/layout/modal/call-me-dialog/call-me-http.service';
import { ClearIconDirective } from '../../app/directives/clear-icon.directive';
import {
  FlightOptionsDialogComponent
} from '../../app/layout/modal/flight-options-dialog/flight-options-dialog.component';
import { SearchboxFlightsMobileService } from '../../app/services/searchbox-flights-mobile.service';
import {
  FlyingCombinedDirectionDialogComponent
} from '../../app/layout/modal/flying-combined-direction-dialog/flying-combined-direction-dialog.component';
import {
  RoundTripDateDialogComponent
} from '../../app/layout/modal/round-trip-date-dialog/round-trip-date-dialog.component';
import {
  FinalContactDialogComponent
} from '../../app/layout/modal/final-contact-dialog/final-contact-dialog.component';
import {
  DepartReturnDateDialogComponent
} from '../../app/layout/modal/depart-return-date-dialog/depart-return-date-dialog.component';
import {
  GetQuoteSuccessDialogComponent
} from '../../app/layout/modal/get-quote-success-dialog/get-quote-success-dialog.component';
import { TransformFlightBracketsPipe } from "../../app/pipes/transform-flight-brackets.pipe";
import { NavigationRoundsComponent } from "../../app/layout/templates/navigation-rounds/navigation-rounds.component";

@NgModule({
  declarations: [
    SafeHtmlPipe,
    PhonePipe,
    SafeUrlPipe,
    ServiceComponent,
    ServiceLuxaryComponent,
    OurServicesComponent,
    OurServicesLuxaryComponent,
    YelpReviewsComponent,
    ScriptInlineComponent,
    TrustpilotBoxComponent,
    TrustpilotGridComponent,
    NewsletterFormComponent,
    MobileAppBlockComponent,
    TrendingDealsComponent,
    SearchboxComponent,
    ClickOutsideDirective,
    InfoLuxaryComponent,
    SearchboxNewGenerationComponent,
    InfoComponent,
    AirlinesBlockComponent,
    SearchboxHotelsComponent,
    SearchboxCruisesComponent,
    SectionOneComponent,
    SectionTwoComponent,
    SectionThreeComponent,
    SectionFourComponent,
    SectionFiveComponent,
    SectionSixComponent,
    SectionSevenComponent,
    ClearIconDirective,
    FlightOptionsDialogComponent,
    FlyingCombinedDirectionDialogComponent,
    RoundTripDateDialogComponent,
    FinalContactDialogComponent,
    DepartReturnDateDialogComponent,
    GetQuoteSuccessDialogComponent,
    TransformFlightBracketsPipe,
    NavigationRoundsComponent
  ],
  exports: [
    SafeHtmlPipe,
    PhonePipe,
    SafeUrlPipe,
    ServiceComponent,
    ServiceLuxaryComponent,
    OurServicesComponent,
    OurServicesLuxaryComponent,
    YelpReviewsComponent,
    ScriptInlineComponent,
    TrustpilotBoxComponent,
    TrustpilotGridComponent,
    NewsletterFormComponent,
    MobileAppBlockComponent,
    TrendingDealsComponent,
    SearchboxComponent,
    ClickOutsideDirective,
    InfoLuxaryComponent,
    SearchboxNewGenerationComponent,
    InfoComponent,
    AirlinesBlockComponent,
    SearchboxHotelsComponent,
    SearchboxCruisesComponent,
    SectionOneComponent,
    SectionTwoComponent,
    SectionThreeComponent,
    SectionFourComponent,
    SectionFiveComponent,
    SectionSixComponent,
    SectionSevenComponent,
    ClearIconDirective,
    FlightOptionsDialogComponent,
    FlyingCombinedDirectionDialogComponent,
    RoundTripDateDialogComponent,
    FinalContactDialogComponent,
    DepartReturnDateDialogComponent,
    GetQuoteSuccessDialogComponent,
    TransformFlightBracketsPipe,
    NavigationRoundsComponent
  ],
  imports: [
    LazyLoadImageModule,
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    NgxPhoneSelectModule,
    TextMaskModule,
    DeferLoadModule
  ],
  providers: [
    CallMeService,
    CallMeHttpService,
    SearchboxFlightsMobileService
  ],
  entryComponents: [
    FlightOptionsDialogComponent,
    FlyingCombinedDirectionDialogComponent,
    RoundTripDateDialogComponent,
    FinalContactDialogComponent,
    DepartReturnDateDialogComponent,
    GetQuoteSuccessDialogComponent,
    NavigationRoundsComponent
  ]
})
export class GeneralModule {
}

