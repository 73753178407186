import {AfterViewChecked, Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser, LocationStrategy} from '@angular/common';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import isEmpty from 'lodash-es/isEmpty';
import eq from 'lodash-es/eq';
import forEach from 'lodash-es/forEach';
import isNil from 'lodash-es/isNil';
import {DataService} from '../shared/shared-data.service';
import {NotifyService} from './services/notify.service';
import {TagService} from './services/tag.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../environments/environment';
import {cookieEnabled} from '../shared/util/cookie-util';
import {Meta} from '@angular/platform-browser';
import {interval, of, Subscription} from 'rxjs';
import {debounceTime, filter, first, takeWhile} from 'rxjs/operators';
import {CookieService} from 'ngx-cookie-service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BeforeCloseDialogComponent} from './layout/modal/before-close-dialog/before-close-dialog.component';
import {DeviceDetectorService} from 'ngx-device-detector';
import {setTimeout$} from './services/utils';

declare let ga: any;
declare global {
  interface Window { Trustpilot: any; }
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked {
  cdnPath: string;
  title = 'app';
  phone: string;
  isPopState = false;
  showConversionCode: boolean;
  showSpinner: boolean;
  subscription: Subscription;
  clientId: string;
  trackExist: string;
  rendered = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              private router: Router,
              private locStrat: LocationStrategy,
              /*private swUpdate: SwUpdate,*/
              private dataService: DataService,
              private notifyService: NotifyService,
              private tagService: TagService,
              private http: HttpClient,
              private meta: Meta,
              private modalService: NgbModal,
              private cookieService: CookieService,
              private deviceService: DeviceDetectorService) {
  }

  ngOnInit() {
    this.meta.addTags([{
      property: 'og:image',
      content: 'https://www.wholesale-flights.com/static-assets/images/fb_logo.jpg'
    }, {
      property: 'og:image:width',
      content: '600'
    }, {
      property: 'og:image:height',
      content: '600'
    }]);

    this.cdnPath = environment.cdnPath;
    if (isPlatformBrowser(this.platformId)) {
      /*this.swUpdate.available.subscribe(event => {
        console.log('[App] Update available: current version is', event.current, 'available version is', event.available);
        this.notifyService.warnWithAvailableUpdates({
          text: 'Our site was updated. We\'re strongly recommended to reload page. Otherwise we cannot guarantee stable work.',
          layout: 'bottomRight',
          animation: {
            open: 'animated fadeInUp',
            close: 'animated fadeOutDown'
          }
        });
      });*/

      window.document.addEventListener('scroll', this.scroll, true);

      this.locStrat.onPopState(() => {
        this.isPopState = true;
      });

      this.router.events.subscribe(event => {
        // Scroll to top if accessing a page, not via browser history stack
        if (event instanceof NavigationEnd && !this.isPopState) {
          window.scrollTo(0, 0);
          this.isPopState = false;
        }

        // Ensures that isPopState is reset
        if (event instanceof NavigationEnd) {
          this.isPopState = false;
        }
      });

      this.router.events.pipe(
          filter(event => event instanceof NavigationStart)
      ).subscribe((event: NavigationStart) => {
        this.showConversionCode = !eq(event.url, '/your-request');
      });

      this.subscription = this.dataService.getData()
        .subscribe(sharedData => {
          if (eq(sharedData.type, 'showSpinner')) {
            this.showSpinner = sharedData.data;
          }
          if (eq(sharedData.type, 'track')) {
            this.trackExist = sharedData.data;
          }
          if (eq(sharedData.type, 'showModal')) {
            if (sharedData.data === true) {
              this.hideScroll();
            } else {
              this.showScroll();
            }
          }
        });
      this.checkCookie();

      interval(50000).pipe(
          takeWhile(() => {
            if (ga) {
              ga.getAll().forEach((tracker) => {
                this.clientId = tracker.get('clientId');
              });
            }
            return !this.clientId && cookieEnabled();
          })).subscribe(
          () => console.log('ClientId = ' + this.clientId),
          () => {
          },
          () => this.saveClientId()
      );

      interval(50000).pipe(
          takeWhile(() => {
            return !this.trackExist && cookieEnabled();
          })).subscribe(
          () => console.log('trackExist = ' + this.trackExist),
          () => {
          },
          () => this.saveClientId()
      );
    }
  }

  saveClientId() {
    if (!!this.clientId && this.trackExist) {
      this.http.get(environment.serverUrl + '/save/client-id', {params: new HttpParams().set('clientId', this.clientId)}).subscribe(() => {
        console.debug('Save clientId complete');
      });
    }
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      window.document.removeEventListener('scroll', this.scroll, true);
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    }
  }

  ngAfterViewChecked () {
    if (window['dataLayer']) {
      window['dataLayer'].push({'event': 'optimize.activate'});
    }
  }

  scroll = (): void => {
    const elements: HTMLCollectionOf<Element> = document.getElementsByClassName('navbar-expand');
    const header: Element = elements[0];
    const offsetTop = document.documentElement.scrollTop;
    if (offsetTop > 82) {
      header.classList.add('fixed-header-change-bg');
    } else {
      header.classList.remove('fixed-header-change-bg');
    }
  }

  onMouseLeave($event) {
    if ($event.clientY > 15) {
      return;
    }
  }

  private showExitModal(): void {
    if (!this.isEmptyLocal(this.cookieService.get('WF_GURU_INSIDER_SENT')) ||
        this.modalService.hasOpenModals() ||
        this.router.url.includes('corporate-private') ||
        this.deviceService.isMobile()) {
      return;
    }
    this.dataService.sendData({ type: 'showModal', data: true });
    const dialogRef = this.modalService.open(BeforeCloseDialogComponent, {
      windowClass: 'before-close-dialog',
      backdropClass: 'hiw-backdrop'
    });
    dialogRef.result.then(
        result => {
          this.dataService.sendData({ type: 'showModal', data: false });
        },
        reason => {
          this.dataService.sendData({ type: 'showModal', data: false });
        }
    );
  }

  checkCookie() {
    this.http.get(environment.serverUrl + '/check-cookie', {params: new HttpParams().set('cookieEnabled', cookieEnabled() ? 'true' : 'false')}).subscribe(() => {
      console.debug('Check cookie complete');
    });
  }

  private hideScroll(): void {
    const scrollSize = window.innerWidth - document.body.scrollWidth;
    const blocks = document.getElementsByClassName('main-block');
    forEach(blocks, (elem) => {
      elem.style.width = 'calc(100% - ' + scrollSize + 'px)';
    });
    document.body.className += ' ' + 'modal-open';
  }

  private showScroll(): void {
    document.body.classList.remove('modal-open');
    document.body.style.paddingRight = '0px';
    const blocks = document.getElementsByClassName('main-block');
    forEach(blocks, (elem) => {
      elem.style.width = '100%';
    });
  }

  private isEmptyLocal(fieldValue): boolean {
    return isEmpty(fieldValue) || isNil(fieldValue);
  }

  get  withForm(): boolean {
    const showForm = !this.router.url.includes('faq') &&
      !this.router.url.includes('about-us') &&
      !this.router.url.includes('terms-and-conditions') &&
      !this.router.url.includes('terms-and-conditions-app') &&
      !this.router.url.includes('insurance-terms') &&
      !this.router.url.includes('privacy-policy') &&
      !this.router.url.includes('corporate') &&
      !this.router.url.includes('reviews') &&
      !this.router.url.includes('our-team') &&
      !this.router.url.includes('your-request') &&
      !this.router.url.includes('privacy-app') &&
      !this.router.url.includes('contact-us') &&
      !this.router.url.includes('app-page') &&
      !this.router.url.includes('all-business-class');

    return showForm;
  }

  get showDefaultHeader(): boolean {
    return !this.router.url.includes('(s:b)') && !this.router.url.includes('(s:c)');
  }
}

