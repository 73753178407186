<div class="close-dialog">
  <div class="modal-header">
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="doNotSendEmail()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h1>Want to be the first to get the latest wholesale flight deals?</h1>
    <p>
      Join the Wholesale Secrets list now!
    </p>
    <form [formGroup]="guruInsiderSpecialsForm">
      <input
        type="email"
        class="form-control"
        id="exampleInputEmail1"
        aria-describedby="emailHelp"
        placeholder="Enter Your E-mail Address Here"
        formControlName="email"
        [(ngModel)]="email"
        [ngClass]="{
          'has-error': !guruInsiderSpecialsForm.get('email').valid && !formValid
        }"
        [attr.disabled]="formSending ? '' : null"
      />
      <div class="bottom-form">
        <button
          type="submit"
          class="btn"
          (click)="sendGuruInsiderSpecials()"
          [attr.disabled]="formSending ? '' : null"
        >
          {{ formSending === false ? "Subscribe" : "Loading..." }}
        </button>
        <a href="javascript:void(0)" (click)="doNotSendEmail()"
          >No, I don't want to pay less</a
        >
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <span
      >*By providing my e-mail and clicking "SUBSCRIBE" I agree to receive
      promotional newsletters. You can opt-out from our mailing list at any
      time. 100% Personal data secure storage guaranteed. You also agree to our
      <a routerLink="terms-and-conditions" (click)="close()"
        >Terms of Use</a
      >
      and
      <a routerLink="privacy-policy" (click)="close()"
        >Privacy Policy</a
      >.</span
    >
  </div>
</div>
