/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./card-dialog.component.scss.shim.ngstyle";
import * as i1 from "../../../../styles/_modal.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "./card-dialog.component";
import * as i4 from "@angular/material/dialog";
var styles_CardDialogComponent = [i0.styles, i1.styles];
var RenderType_CardDialogComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_CardDialogComponent, data: {} });
export { RenderType_CardDialogComponent as RenderType_CardDialogComponent };
export function View_CardDialogComponent_0(_l) { return i2.ɵvid(0, [], null, null); }
export function View_CardDialogComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-card-dialog", [], null, null, null, View_CardDialogComponent_0, RenderType_CardDialogComponent)), i2.ɵdid(1, 114688, null, 0, i3.CardDialogComponent, [i4.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CardDialogComponentNgFactory = i2.ɵccf("app-card-dialog", i3.CardDialogComponent, View_CardDialogComponent_Host_0, {}, {}, []);
export { CardDialogComponentNgFactory as CardDialogComponentNgFactory };
