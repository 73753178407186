import * as Noty from 'noty';
import defaultTo from 'lodash-es/defaultTo';
import map from 'lodash-es/map';
import { DataService } from '../../shared/shared-data.service';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/shared-data.service";
var NotifyService = /** @class */ (function () {
    function NotifyService(dataService) {
        var _this = this;
        this.dataService = dataService;
        this.defaults = {
            theme: 'relax',
            layout: 'topCenter',
            type: 'information',
            closeWith: [],
            callbacks: {
                onShow: function () {
                    _this.dataService.sendData({ type: 'notyShowing', data: true });
                },
                onClose: function () {
                    _this.dataService.sendData({ type: 'notyShowing', data: false });
                },
            },
            animation: {
                open: 'animated fadeInDown',
                close: 'animated fadeOutUp'
            }
        };
    }
    NotifyService.prototype.warn = function (text, options) {
        var opts = Object.assign({}, this.defaults, {
            type: 'warning',
            text: text
        }, options);
        var noty = new Noty(opts);
        noty.show();
        return noty;
    };
    NotifyService.prototype.warnWithAvailableUpdates = function (options) {
        var _this = this;
        var noty;
        var buttons = [{
                text: 'RELOAD',
                classes: 'btn apply-update',
                callback: function () {
                    _this.dataService.sendData({ type: 'updatesApplied', data: true });
                    window.location.reload();
                }
            },
            {
                text: 'DISMISS',
                classes: 'btn float-right dismiss-update',
                callback: function () {
                    _this.dataService.sendData({ type: 'updatesApplied', data: false });
                    noty.close();
                }
            }];
        var opts = Object.assign({}, this.defaults, {
            type: 'warning',
            buttons: map(buttons, function (button) { return Noty.button(button.text, button.classes, defaultTo(button.callback, function () {
            })); }).value()
        }, options);
        noty = new Noty(opts);
        noty.show();
        return noty;
    };
    NotifyService.prototype.error = function (text, options) {
        var opts = Object.assign({}, this.defaults, {
            type: 'error',
            text: text,
        }, options);
        var noty = new Noty(opts);
        noty.show();
        return noty;
    };
    NotifyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotifyService_Factory() { return new NotifyService(i0.ɵɵinject(i1.DataService)); }, token: NotifyService, providedIn: "root" });
    return NotifyService;
}());
export { NotifyService };
