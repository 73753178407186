import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConstantService {
  private _constants = {};

  constructor() { }

  public getConstant(key: string): any {
    return this._constants[key];
  }

  public setConstant(key: string, value: any): void {
    this._constants[key] = value;
  }
}
