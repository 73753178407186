import { Component, OnInit } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {MatDialogRef} from "@angular/material/dialog";
import {HowItWorksDialogComponent} from "../how-it-works-dialog/how-it-works-dialog.component";

@Component({
  selector: 'app-card-dialog',
  templateUrl: './card-dialog.component.html',
  styleUrls: ['./card-dialog.component.scss', './../../../../styles/_modal.scss']
})
export class CardDialogComponent implements OnInit {
  cdnPath: string;
  constructor(public dialogRef: MatDialogRef<HowItWorksDialogComponent>) {
  }

  ngOnInit() {
    this.cdnPath = environment.cdnPath;
  }

  onCloseDialog(): void {

    this.dialogRef.close();
  }

}
