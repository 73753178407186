var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HomeService } from './home.service';
import { ComponentBase } from '../../common/ComponentBase';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import isNil from 'lodash-es/isNil';
var HomeComponent = /** @class */ (function (_super) {
    __extends(HomeComponent, _super);
    function HomeComponent(homeService, router) {
        var _this = _super.call(this) || this;
        _this.homeService = homeService;
        _this.router = router;
        _this.showContentPrice = false;
        _this.cdnPath = environment.cdnPath;
        return _this;
    }
    Object.defineProperty(HomeComponent.prototype, "isBusinessLand", {
        get: function () {
            return this.landingClass === 'First' ? false : true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HomeComponent.prototype, "landingClass", {
        get: function () {
            return isNil(this.router) || isNil(this.router.url) || !this.router.url.includes('first-class') ? 'Business' : 'First';
        },
        enumerable: true,
        configurable: true
    });
    return HomeComponent;
}(ComponentBase));
export { HomeComponent };
