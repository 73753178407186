import isEmpty from 'lodash-es/isEmpty';
import isNil from 'lodash-es/isNil';
export  class ComponentBase {
  static readonly WORD_SEPARATOR = '-';

  splitAndCapitalize(value: string): string {
    if (value.indexOf(ComponentBase.WORD_SEPARATOR)) {
      let name = '';
      const words = value.split(ComponentBase.WORD_SEPARATOR);
      for (let i = 0; i < words.length; i++) {
        name += this.capitalize(words[i]);
        name += ' ';
      }
      return name.trim();
    }
    return this.capitalize(value);
  }

  capitalize(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  buildLink(name: string, isBusiness?: boolean): string {
    const landingClass = isNil(isBusiness) || isBusiness === true ? '/business' : '/first';
    if (isNil(name) || isEmpty(name)) {
      return landingClass + '-class-flights';
    }
    return 'flights' === name.toLowerCase() || 'airlines' === name.toLowerCase() ?
      landingClass + '-class/' + name.toLowerCase() : landingClass + '-class-flights-to/' + name.toLowerCase();
  }

  buildAirlineLink(name: string, isBusiness?: boolean): string {
    const landingClass = isNil(isBusiness) || isBusiness === true ? '/business' : '/first';
    if (isNil(name) || isEmpty(name)) {
      return landingClass + '-class-airlines';
    }
    return landingClass + '-class-airlines/' + name.toLowerCase();
  }
}
