import { Injector } from '@angular/core';
import { of, ReplaySubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import isEmpty from 'lodash-es/isEmpty';
import { ApplicationEventBroadcaster } from '../../shared/application.event.broadcaster';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../storage/universal.inject";
import * as i3 from "../../shared/application.event.broadcaster";
var TrackingService = /** @class */ (function () {
    function TrackingService(http, appStorage, platformId, injector, broadcaster) {
        var _this = this;
        this.http = http;
        this.appStorage = appStorage;
        this.platformId = platformId;
        this.injector = injector;
        this.broadcaster = broadcaster;
        this.trackInCookie = false;
        this.startTracking = false;
        this.broadcaster.onType(TrackingStartRequestEvent).subscribe(function () {
            _this.startTracking = true;
        });
        this.broadcaster.onType(TrackingFinishRequestEvent).subscribe(function () {
            _this.startTracking = false;
            _this.clear();
        });
    }
    TrackingService.prototype.getTrack = function () {
        console.debug('Track request...');
        this.trackInCookie = !!this.appStorage.getItem('WF_T');
        if (this.trackInCookie && !this.queryStringParams()) {
            console.debug('Track exist in cookie...');
            return of(this.appStorage.getItem('WF_T'));
        }
        else {
            if (this._current) {
                console.debug('Current track...');
                return this._current;
            }
            console.debug('New track...');
            return this._current = cacheable(this.http.get(environment.serverUrl + '/track'));
        }
    };
    TrackingService.prototype.clear = function () {
        this._current = null;
    };
    TrackingService.prototype.queryStringParams = function () {
        if (!isPlatformBrowser(this.platformId)) {
            var req = this.injector.get('REQUEST');
            return !isEmpty(req.query);
        }
        return !!location.search;
    };
    TrackingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TrackingService_Factory() { return new TrackingService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppStorage), i0.ɵɵinject(i0.PLATFORM_ID), i0.ɵɵinject(i0.INJECTOR), i0.ɵɵinject(i3.ApplicationEventBroadcaster)); }, token: TrackingService, providedIn: "root" });
    return TrackingService;
}());
export { TrackingService };
export function cacheable(o) {
    var replay = new ReplaySubject(1);
    o.subscribe(function (x) { return replay.next(x); }, function (x) { return replay.error(x); }, function () { return replay.complete(); });
    return replay.asObservable();
}
var TrackingEvent = /** @class */ (function () {
    function TrackingEvent() {
    }
    return TrackingEvent;
}());
export { TrackingEvent };
var TrackingStartRequestEvent = /** @class */ (function () {
    function TrackingStartRequestEvent() {
    }
    return TrackingStartRequestEvent;
}());
export { TrackingStartRequestEvent };
var TrackingFinishRequestEvent = /** @class */ (function () {
    function TrackingFinishRequestEvent() {
    }
    return TrackingFinishRequestEvent;
}());
export { TrackingFinishRequestEvent };
