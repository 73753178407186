export const CruiseLinesList: Array<{ shortName: string , value: string }> = [
  { shortName: 'Any', value: 'Any Cruise Line' },
  { shortName: 'Carnival', value: 'Carnival Cruise Line' },
  { shortName: 'Celebrity Cruises', value: 'Celebrity Cruises' },
  { shortName: 'Norwegian', value: 'Norwegian Cruise Line' },
  { shortName: 'Royal Caribbean', value: 'Royal Caribbean' },
  { shortName: 'Viking River', value: 'Viking River' },
  { shortName: 'AmaWaterways', value: 'AmaWaterways' },
  { shortName: 'American', value: 'American Cruise Lines' },
  { shortName: 'American Queen Voyages', value: 'American Queen Voyages' },
  { shortName: 'Avalon Waterways', value: 'Avalon Waterways' },
  { shortName: 'Azamara', value: 'Azamara' },
  { shortName: 'Costa Cruises', value: 'Costa Cruises' },
  { shortName: 'Crystal Cruises', value: 'Crystal Cruises' },
  { shortName: 'Cunard', value: 'Cunard' },
  { shortName: 'Disney', value: 'Disney Cruise Line' },
  { shortName: 'Explora Journeys', value: 'Explora Journeys' },
  { shortName: 'Holland America Line', value: 'Holland America Line' },
  { shortName: 'Hurtigruten Cruises', value: 'Hurtigruten Cruises' },
  { shortName: 'MSC Cruises', value: 'MSC Cruises' },
  { shortName: 'Oceania Cruises', value: 'Oceania Cruises' },
  { shortName: 'Paul Gauguin Cruises', value: 'Paul Gauguin Cruises' },
  { shortName: 'Ponant', value: 'Ponant' },
  { shortName: 'Princess Cruises', value: 'Princess Cruises' },
  { shortName: 'Regent Seven Seas Cruises', value: 'Regent Seven Seas Cruises' },
  { shortName: 'Ritz-Carlton Yacht Collection', value: 'Ritz-Carlton Yacht Collection' },
  { shortName: 'Seabourn', value: 'Seabourn' },
  { shortName: 'SeaDream Yacht Club', value: 'SeaDream Yacht Club' },
  { shortName: 'Silversea Cruises', value: 'Silversea Cruises' },
  { shortName: 'Uniworld River Cruises', value: 'Uniworld River Cruises' },
  { shortName: 'Viking Ocean', value: 'Viking Ocean' },
  { shortName: 'Virgin Voyages', value: 'Virgin Voyages' },
  { shortName: 'Windstar Cruises', value: 'Windstar Cruises' }
];
