var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var SearchboxFlightsMobileService = /** @class */ (function () {
    function SearchboxFlightsMobileService() {
        this.flightQuoteData = {
            queryCruiseParams: {
                tripType: null,
                cabin: null,
                passengers: null
            }
        };
    }
    SearchboxFlightsMobileService.prototype.setFlightOptions = function (flightOptions) {
        this.flightQuoteData.queryCruiseParams = __assign({}, flightOptions);
    };
    SearchboxFlightsMobileService.prototype.getFlightQuoteData = function () {
        return this.flightQuoteData;
    };
    return SearchboxFlightsMobileService;
}());
export { SearchboxFlightsMobileService };
