import { Component, OnInit } from '@angular/core';

import { CallMeService } from '../../modal/call-me-dialog/call-me.service';

@Component({
  selector: 'app-section-three',
  templateUrl: './section-three.component.html',
  styleUrls: ['./section-three.component.scss']
})
export class SectionThreeComponent implements OnInit {

  constructor(
      private callMeService: CallMeService
  ) { }

  public ngOnInit(): void {
  }

  public openCallMeDialog(): void {
    this.callMeService.openCallMeDialog();
  }
}
