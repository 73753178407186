import {Injectable} from '@angular/core';
import * as Noty from 'noty';
import defaultTo from 'lodash-es/defaultTo';
import map from 'lodash-es/map';
import {DataService} from '../../shared/shared-data.service';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {

  constructor(private dataService: DataService) {
  }

  private readonly defaults: Noty.Options = <Noty.Options>{
    theme: 'relax',
    layout: 'topCenter',
    type: 'information',
    closeWith: [],
    callbacks: {
      onShow: () => {
        this.dataService.sendData({type: 'notyShowing', data: true});
      },
      onClose: () => {
        this.dataService.sendData({type: 'notyShowing', data: false});
      },
    },
    animation: {
      open: 'animated fadeInDown',
      close: 'animated fadeOutUp'
    }
  };

  public warn(text: string, options?: Noty.Options): Noty {
    const opts: Noty.Options = Object.assign({}, this.defaults, <Noty.Options>{
      type: 'warning',
      text: text
    }, options);
    const noty = new Noty(opts);
    noty.show();
    return noty;
  }

  public warnWithAvailableUpdates(options?: Noty.Options): Noty {
    let noty;
    const buttons = [{
      text: 'RELOAD',
      classes: 'btn apply-update',
      callback: () => {
        this.dataService.sendData({type: 'updatesApplied', data: true});
        window.location.reload();
      }
    },
      {
        text: 'DISMISS',
        classes: 'btn float-right dismiss-update',
        callback: () => {
          this.dataService.sendData({type: 'updatesApplied', data: false});
          noty.close();
        }
      }];
    const opts: Noty.Options = Object.assign({}, this.defaults, <Noty.Options>{
      type: 'warning',
      buttons: map(buttons, button => Noty.button(button.text, button.classes, defaultTo(button.callback, () => {
      }))).value()
    }, options);
    noty = new Noty(opts);
    noty.show();
    return noty;
  }

  public error(text: string, options?: Noty.Options): Noty {
    const opts: Noty.Options = Object.assign({}, this.defaults, <Noty.Options>{
      type: 'error',
      text: text,
    }, options);
    const noty = new Noty(opts);
    noty.show();
    return noty;
  }

}

export interface NotyButtons {
  text: string;
  classes?: string;
  callback?: () => any;
}
