import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {DataWrapper} from '../app/model/data-wrapper';

@Injectable()
export class DataService {
    private subject = new Subject<DataWrapper>();

    sendData(wrapper: DataWrapper) {
        this.subject.next(wrapper);
    }

    clearData() {
        this.subject.next();
    }

    getData(): Observable<DataWrapper> {
        return this.subject.asObservable();
    }
}
