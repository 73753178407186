var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ElementRef, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from "@angular/forms";
import { formatDate } from "@angular/common";
import * as _ from 'lodash';
import { ContactData } from "../../../model/contact-data";
import { phoneMaskMobile } from "../../../app.constants";
import { NotifyService } from "../../../services/notify.service";
import { SearchboxService } from "../../templates/searchbox/searchbox.service";
var FinalContactDialogComponent = /** @class */ (function () {
    function FinalContactDialogComponent(activeModal, formBuilder, notifyService, searchboxService) {
        this.activeModal = activeModal;
        this.formBuilder = formBuilder;
        this.notifyService = notifyService;
        this.searchboxService = searchboxService;
        this.personalDataValid = true;
        this.contactData = new ContactData();
        this.phoneMask = phoneMaskMobile;
        this.formSending = false;
    }
    FinalContactDialogComponent.prototype.ngOnInit = function () {
        this.initPersonalDataForm();
        this.formValue = _.cloneDeep(this.searchboxActiveForm.value);
    };
    FinalContactDialogComponent.prototype.onPhoneNumberChange = function (event) {
        var inputElement = event.target;
        if (inputElement.value.indexOf('(') < 0) {
            this.contactData.phone = inputElement.value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        }
        if (this.contactData.phone.indexOf('+') < 0) {
            this.contactData.phone = '+1 ' + this.contactData.phone;
        }
    };
    FinalContactDialogComponent.prototype.noWhitespaceValidator = function (control) {
        var isWhitespace = (control.value || '').trim().length === 0;
        var isValid = !isWhitespace;
        return isValid ? null : { 'isEmpty': true };
    };
    FinalContactDialogComponent.prototype.initPersonalDataForm = function () {
        this.personalDataForm = this.formBuilder.group({
            firstName: ['', Validators.compose([Validators.required, this.noWhitespaceValidator])],
            email: ['', Validators.compose([Validators.required, Validators.email])],
            phone: ['', Validators.compose([
                    Validators.required,
                    Validators.pattern('^\\+1 [(]\\d{3}[)][\\s]\\d{3}[-]\\d{4}$'),
                    this.phoneValidator()
                ])]
        });
        this.contactData = __assign({}, this.contactData, this.searchboxContactData);
        if (this.contactData.phone) {
            this.contactData.phone = '+1 ' + this.contactData.phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        }
    };
    FinalContactDialogComponent.prototype.getQuote = function () {
        var _this = this;
        if (this.personalDataForm.get('phone').hasError('phoneFormatError')) {
            this.personalDataValid = false;
            this.notifyService.warn('Incorrect area code', {
                closeWith: ['click', 'button'],
                timeout: 5000
            });
            return;
        }
        if (this.personalDataForm.valid && (this.searchboxActiveForm.valid && !this.isMultiCity ||
            this.searchboxActiveForm.valid && this.isMultiCityValid)) {
            this.personalDataValid = true;
            this.formSending = true;
            var sendContactData = _.clone(this.contactData);
            var phonePlusIndex = sendContactData.phone.indexOf('+');
            if (phonePlusIndex >= 0) {
                sendContactData.phone = sendContactData.phone.slice(phonePlusIndex + 2);
                sendContactData.phone = _.replace(sendContactData.phone, new RegExp('[ ()-]', 'g'), '');
            }
            this.searchboxService.sendRequest(sendContactData).subscribe(function (response) {
                _this.formSending = false;
                if (_.eq(response.status, 'FAILED')) {
                    alert(response.data.details);
                }
                else {
                    _this.closeDialog(true);
                }
            }, function (error) {
                _this.formSending = false;
                console.warn('Can\'t process your request now. Please try later.');
            });
        }
        else {
            this.personalDataValid = false;
            this.notifyService.warn('Please fill flight data', {
                closeWith: ['click', 'button'],
                timeout: 10000
            });
        }
    };
    FinalContactDialogComponent.prototype.capitalize = function (value) {
        return _.capitalize(value);
    };
    FinalContactDialogComponent.prototype.prepareContactData = function () {
        var transformedPhone = _.replace(this.contactData.phone, new RegExp('[ ()-]', 'g'), '');
        return {
            firstName: this.contactData.firstName,
            email: this.contactData.email,
            phone: !this.contactData.phone ? null : this.contactData.phone[0] === '+' ? transformedPhone.slice(2) : transformedPhone,
            cCode: '1'
        };
    };
    FinalContactDialogComponent.prototype.extractTextFromBrackets = function (from, to) {
        if (!from || !to || from.indexOf('(') < 0 || to.indexOf('(') < 0) {
            return '';
        }
        var regex = /\(([^)]+)\)/;
        var matchFrom = from.match(regex);
        var matchTo = to.match(regex);
        return matchFrom[1] + "-" + matchTo[1];
    };
    FinalContactDialogComponent.prototype.hasDirectionValue = function (from, to) {
        return !(!from || !to || from.indexOf('(') < 0 || to.indexOf('(') < 0);
    };
    FinalContactDialogComponent.prototype.hasDateValue = function (date) {
        return date && !!date.month;
    };
    FinalContactDialogComponent.prototype.getDateValue = function (date) {
        if (this.hasDateValue(date)) {
            var formattedDate = new Date(date.year, date.month - 1, date.day);
            return formatDate(formattedDate, 'MM/dd', 'en-US');
        }
        else {
            return '';
        }
    };
    FinalContactDialogComponent.prototype.closeDialog = function (isSuccess, moveBack) {
        if (isSuccess === void 0) { isSuccess = false; }
        if (moveBack === void 0) { moveBack = false; }
        this.activeModal.close({
            isSuccess: isSuccess,
            contactData: this.prepareContactData(),
            moveBack: moveBack
        });
    };
    FinalContactDialogComponent.prototype.phoneValidator = function () {
        return function (control) {
            if (!_.isEmpty(control.value) && control.value.startsWith('+1 (1')) {
                return { phoneFormatError: true };
            }
            else {
                return null;
            }
        };
    };
    return FinalContactDialogComponent;
}());
export { FinalContactDialogComponent };
