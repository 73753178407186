import { PipeTransform } from '@angular/core';
import { transformToSquareBrackets } from '../model/transform-brackets';
var TransformFlightBracketsPipe = /** @class */ (function () {
    function TransformFlightBracketsPipe() {
    }
    TransformFlightBracketsPipe.prototype.transform = function (value) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return value.map(function (element) {
            return transformToSquareBrackets(element);
        });
    };
    return TransformFlightBracketsPipe;
}());
export { TransformFlightBracketsPipe };
