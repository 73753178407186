/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./flight-options-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./flight-options-dialog.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "../../../services/searchbox-flights-mobile.service";
var styles_FlightOptionsDialogComponent = [i0.styles];
var RenderType_FlightOptionsDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FlightOptionsDialogComponent, data: {} });
export { RenderType_FlightOptionsDialogComponent as RenderType_FlightOptionsDialogComponent };
function View_FlightOptionsDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn-back"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "icon-back"]], null, null, null, null, null))], null, null); }
function View_FlightOptionsDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.selectedTravelers = _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit === _co.selectedTravelers); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 3, 0, currVal_1); }); }
export function View_FlightOptionsDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 54, "div", [["aria-hidden", "true"], ["class", "modal show"], ["role", "dialog"], ["style", "display: block;"], ["tabindex", "-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 53, "div", [["class", "modal-dialog"], ["role", "document"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 52, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FlightOptionsDialogComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h2", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Flight options"])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["data-dismiss", "modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveAndClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(11, 0, null, null, 40, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [["class", "list-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Trip type"])), (_l()(), i1.ɵeld(14, 0, null, null, 12, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 3, "li", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.selectedTripType = "Round") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "a", [["id", "round-trip"], ["valtype", "round"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Round-Trip"])), (_l()(), i1.ɵeld(19, 0, null, null, 3, "li", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.selectedTripType = "OneWay") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 2, "a", [["id", "one-way"], ["valtype", "oneway"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["One-way"])), (_l()(), i1.ɵeld(23, 0, null, null, 3, "li", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.selectedTripType = "MultiCity") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 2, "a", [["id", "multi-city"], ["valtype", "multi"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Multi-city"])), (_l()(), i1.ɵeld(27, 0, null, null, 1, "span", [["class", "list-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Travelers"])), (_l()(), i1.ɵeld(29, 0, null, null, 3, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_FlightOptionsDialogComponent_2)), i1.ɵdid(31, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpad(32, 10), (_l()(), i1.ɵeld(33, 0, null, null, 1, "span", [["class", "list-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cabin type"])), (_l()(), i1.ɵeld(35, 0, null, null, 16, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(36, 0, null, null, 3, "li", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.selectedCabin = "BUSINESS") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(37, 0, null, null, 2, "a", [["id", "BUSINESS"]], null, null, null, null, null)), (_l()(), i1.ɵeld(38, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Business"])), (_l()(), i1.ɵeld(40, 0, null, null, 3, "li", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.selectedCabin = "FIRST") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(41, 0, null, null, 2, "a", [["id", "FIRST"]], null, null, null, null, null)), (_l()(), i1.ɵeld(42, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["First"])), (_l()(), i1.ɵeld(44, 0, null, null, 3, "li", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.selectedCabin = "PREMIUMECO") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(45, 0, null, null, 2, "a", [["id", "PREMIUMECO"]], null, null, null, null, null)), (_l()(), i1.ɵeld(46, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Premium Economy"])), (_l()(), i1.ɵeld(48, 0, null, null, 3, "li", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.selectedCabin = "ECONOMY") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(49, 0, null, null, 2, "a", [["id", "ECONOMY"]], null, null, null, null, null)), (_l()(), i1.ɵeld(50, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Economy"])), (_l()(), i1.ɵeld(52, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(53, 0, null, null, 1, "button", [["class", "btn"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveAndClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasBackButton; _ck(_v, 5, 0, currVal_0); var currVal_4 = _ck(_v, 32, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10); _ck(_v, 31, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.selectedTripType === "Round"); _ck(_v, 15, 0, currVal_1); var currVal_2 = (_co.selectedTripType === "OneWay"); _ck(_v, 19, 0, currVal_2); var currVal_3 = (_co.selectedTripType === "MultiCity"); _ck(_v, 23, 0, currVal_3); var currVal_5 = (_co.selectedCabin === "BUSINESS"); _ck(_v, 36, 0, currVal_5); var currVal_6 = (_co.selectedCabin === "FIRST"); _ck(_v, 40, 0, currVal_6); var currVal_7 = (_co.selectedCabin === "PREMIUMECO"); _ck(_v, 44, 0, currVal_7); var currVal_8 = (_co.selectedCabin === "ECONOMY"); _ck(_v, 48, 0, currVal_8); }); }
export function View_FlightOptionsDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-flight-options-dialog", [], null, null, null, View_FlightOptionsDialogComponent_0, RenderType_FlightOptionsDialogComponent)), i1.ɵdid(1, 114688, null, 0, i3.FlightOptionsDialogComponent, [i4.NgbActiveModal, i5.SearchboxFlightsMobileService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FlightOptionsDialogComponentNgFactory = i1.ɵccf("app-flight-options-dialog", i3.FlightOptionsDialogComponent, View_FlightOptionsDialogComponent_Host_0, {}, {}, []);
export { FlightOptionsDialogComponentNgFactory as FlightOptionsDialogComponentNgFactory };
