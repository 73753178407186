export class ContactData {
  firstName: string;
  email: string;
  cCode: string;
  phone: string;
  formPhone: string;
  memo: string;
  comments: string;
  agentId: string;
  name: string;
}

export class ExtendedContactData extends ContactData {
  referralCode: string;
}
