<section id="services">
    <div class="container col-xl-12 px-3 py-4">
  
      <div class="row featurette align-items-center justify-content-center">
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-5 text-center text-lg-left">
          <p class="featurette-heading"><span>Save On</span> Business Class Flights, Cruises, And Hotels</p> 
          <p class="lead">Leveraging our strong affiliations with major consolidators and direct access to unpublished rates, private fares, and enticing promotional deals, we can offer the most competitive pricing for international flights, luxury cruises, and premium hotels.</p>
          <a class="btn btn-primary mb-3 bth-primary" (click)="openCallMeDialog()">Get a FREE Quote</a>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6"><img class="select-box__icon" src="assets/img/services/flights/01.png" alt="Services" aria-hidden="true"/></div>
      </div>
    
      <div class="row featurette pt-5 align-items-center justify-content-center">
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-5 order-lg-3 text-center text-lg-left">
          <p class="featurette-heading">How Much Will <span>I Save?</span></p>
          <p class="lead">The price depends on several factors such as your travel destinations, dates, and availability. In most cases, we can reduce costs by 20-50% below retail prices, and occasionally, the savings can be even greater. Request a quote today, and we'll promptly provide you with a quote.  </p>
          <a class="btn btn-primary mb-3 bth-primary" (click)="openCallMeDialog()">Get a FREE Quote</a>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6"><img class="select-box__icon" src="assets/img/services/flights/02.png" alt="Services" aria-hidden="true"/></div>
      </div>
  
      <div class="row featurette pt-5 align-items-center justify-content-center">
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-5 text-center text-lg-left">
          <p class="featurette-heading">Never Wait On Hold Again <span>A Dedicated Travel Specialist</span></p>
          <p class="lead">Your personal travel specialist takes care of all the details, from securing preferred seating and catering to specific meal preferences, to arranging travel insurance and ensuring you earn mileage rewards. Essentially, our expertise revolves around finding solutions that save you both time and money! </p>
          <a class="btn btn-primary mb-3 bth-primary" (click)="openCallMeDialog()">Request a Consultation</a>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6"><img class="select-box__icon" src="assets/img/services/flights/03.png" alt="Services" aria-hidden="true"/></div>
      </div>
  
    </div>
  </section>
