import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { SearchboxFlightsMobileService } from '../../../services/searchbox-flights-mobile.service';
import { FlightOptions } from '../../../model/flight-mobile.interface';

@Component({
  selector: 'app-flight-options-dialog',
  templateUrl: './flight-options-dialog.component.html',
  styleUrls: ['./flight-options-dialog.component.scss']
})
export class FlightOptionsDialogComponent implements OnInit {
  public selectedTravelers: number;
  public selectedCabin: string;
  public selectedTripType: string;
  public hasBackButton: boolean = false;
  public searchboxFlightsMobileData: FlightOptions;

  constructor(
    private activeModal: NgbActiveModal,
    private searchboxFlightsMobileService: SearchboxFlightsMobileService
  ) {
    this.searchboxFlightsMobileData = this.searchboxFlightsMobileService.getFlightQuoteData().queryCruiseParams;
    this.selectedTravelers = this.searchboxFlightsMobileData.passengers;
    this.selectedCabin = this.searchboxFlightsMobileData.cabin;
    this.selectedTripType = this.searchboxFlightsMobileData.tripType;
  }

  public ngOnInit(): void {
  }

  public saveAndClose(): void {
    this.searchboxFlightsMobileService.setFlightOptions({
      cabin: this.selectedCabin,
      passengers: this.selectedTravelers,
      tripType: this.selectedTripType
    });
    this.closeDialog();
  }

  public closeDialog(): void {
    this.activeModal.close();
  }
}
