import {Component, OnInit} from '@angular/core';
import {WriteReviewService} from './write-review.service';
import {User} from '../../../model/user';
import {Review} from '../../../model/review';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-write-review-dialog',
  templateUrl: './write-review-dialog.component.html',
  styleUrls: ['./write-review-dialog.component.scss', './../../../../styles/_modal.scss'],
  providers: [WriteReviewService]
})
export class WriteReviewDialogComponent implements OnInit {

  reviewUsers: User[] = [];
  writeReviewForm: FormGroup;
  reviewData: Review = new Review();
  reviewFormSending = false;
  userId: string;

  constructor(private activeModal: NgbActiveModal,
              private writeReviewService: WriteReviewService,
              private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.initReviewForm();
    if (this.userId) {
      this.reviewData.agent = this.userId;
      this.writeReviewForm.get('agent').setValue(this.userId);
    } else {
      this.writeReviewService.getReviewUsers().subscribe(
        (response: User[]) => {
          this.reviewUsers = response;
        },
        error => {
          console.error('Error during get review agents', error);
        });
    }
  }

  initReviewForm() {
    this.writeReviewForm = this.formBuilder.group({
      agent: ['', Validators.required],
      name: ['', Validators.required],
      rating: ['', Validators.required],
      reviewText: ['', Validators.required]
    });
  }

  onCloseDialog(): void {
    this.activeModal.close();
  }

  submitReview() {
    if (this.writeReviewForm.valid) {
      this.reviewData.rating = this.writeReviewForm.get('rating').value;
      this.writeReviewService.submitReview(this.reviewData).subscribe(
        response => {
          this.reviewFormSending = true;
        });
    }
  }

}
