import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[appClickOutside]'
})
export class ClickOutsideDirective {

  @Output() public appClickOutside = new EventEmitter();
  @Output() public appTouchOutside = new EventEmitter();

  constructor(private _elementRef: ElementRef) {
  }

  @HostListener('document:click', ['$event'])
  public onClick(event: Event) {
    const isClickedInside = this._elementRef.nativeElement.contains(event.target);
    if (!isClickedInside) {
      this.appClickOutside.emit(event);
    }
  }

  @HostListener('document:touchstart', ['$event'])
  public onTouch(event: Event) {
    const isClickedInside = this._elementRef.nativeElement.contains(event.target);
    if (!isClickedInside) {
      this.appTouchOutside.emit(event);
    }
  }
}
