<div class="modal show" tabindex="-1" role="dialog" aria-hidden="true" style="display: block;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <h2 class="modal-title">Select travel airports</h2>
                <h2 *ngIf="!isMultiCity" class="modal-subtitle">1 of 3</h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeDialog()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <form>
                    <div *ngIf="false" class="form-input info-input mb-2">
                        <fieldset disabled>
                            <label for="flightOptions">Flight options</label>
                            <input type="text" class="form-control" id="flightOptions"
                                   placeholder="Round-Trip | 1 Traveler | Business Class">
                            <i class="icon-down"></i>
                        </fieldset>
                    </div>

                    <div class="form-input"
                         [class.selected]="activeControl === departureControl"
                         (click)="activeControl = departureControl">
                        <label for="departure">From</label>
                        <input [formControl]="departureControl"
                               type="text"
                               class="form-control"
                               id="departure"
                               placeholder="Enter a country, city or airport"
                               [(ngModel)]="departureModel"
                               #departElement
                               appClearIcon>
                        <span class="xIcon"></span>
                    </div>

                    <div class="form-input mt10"
                         [class.selected]="activeControl === destinationControl"
                         (click)="activeControl = destinationControl">
                        <label for="destination">To</label>
                        <input [formControl]="destinationControl"
                               type="text"
                               class="form-control"
                               id="destination"
                               placeholder="Enter a country, city or airport"
                               [(ngModel)]="destinationModel"
                               #destinationElement
                               appClearIcon>
                        <span class="xIcon"></span>
                    </div>

                </form>
            </div>

            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-sm-10 col-md-8 col-lg-6">
                        <form>
                            <div class="list-box">
                                <ng-container *ngIf="getControlItemsList() | async as data">
                                    <ng-container *ngIf="data.length && activeControl.value">
                                        <li *ngFor="let item of data | transformFlightBrackets"
                                            (click)="selectAirport(item)">
                                            <div class="airports">
                                                <i class="icon-plane"></i>
                                                <div class="airport-name">
                                                    <span>{{item}}</span>
                                                </div>
                                            </div>
                                        </li>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button [disabled]="isDisableSaveButton()"
                        type="submit"
                        class="btn"
                        (click)="saveAndClose()">
                    Next
                </button>

                <app-navigation-rounds *ngIf="!isMultiCity"></app-navigation-rounds>
            </div>

        </div>
    </div>
</div>
