import { Component } from '@angular/core';
import {HomeService} from './home.service';
import {ComponentBase} from '../../common/ComponentBase';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';
import isNil from 'lodash-es/isNil';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends ComponentBase {
  cdnPath: string;
  showContentPrice = false;
  constructor(private homeService: HomeService,  public router: Router) {
    super();
    this.cdnPath = environment.cdnPath;
  }

  get isBusinessLand(): boolean {
    return this.landingClass === 'First' ? false : true;
  }

  get landingClass() {
    return isNil(this.router) || isNil(this.router.url) || !this.router.url.includes('first-class') ? 'Business' : 'First';
  }
}
