/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./get-quote-success-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./get-quote-success-dialog.component";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
var styles_GetQuoteSuccessDialogComponent = [i0.styles];
var RenderType_GetQuoteSuccessDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GetQuoteSuccessDialogComponent, data: {} });
export { RenderType_GetQuoteSuccessDialogComponent as RenderType_GetQuoteSuccessDialogComponent };
export function View_GetQuoteSuccessDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["aria-hidden", "true"], ["class", "modal show"], ["role", "dialog"], ["style", "display: block;"], ["tabindex", "-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "modal-dialog"], ["role", "document"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h2", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Congratulations!"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["data-dismiss", "modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "i", [["class", "icon-support"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["class", "list-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["We received your request, a travel expert will contact you shortly from a phone number with a 650 area code."])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [["class", "btn"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Ok"]))], null, null); }
export function View_GetQuoteSuccessDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-traveler-phone-dialog", [], null, null, null, View_GetQuoteSuccessDialogComponent_0, RenderType_GetQuoteSuccessDialogComponent)), i1.ɵdid(1, 114688, null, 0, i2.GetQuoteSuccessDialogComponent, [i3.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GetQuoteSuccessDialogComponentNgFactory = i1.ɵccf("app-traveler-phone-dialog", i2.GetQuoteSuccessDialogComponent, View_GetQuoteSuccessDialogComponent_Host_0, {}, {}, []);
export { GetQuoteSuccessDialogComponentNgFactory as GetQuoteSuccessDialogComponentNgFactory };
