import {Injectable} from '@angular/core';
import {RestResponse} from '../../../model/rest-response';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class HeaderService {

  constructor(private http: HttpClient) {
  }

  isNight(): Observable<RestResponse> {
    return this.http.get<RestResponse>(environment.serverUrl + '/contact-info/is-night');
  }

  getPhone(): Observable<RestResponse> {
    return this.http.get<RestResponse>(environment.serverUrl + '/contact-info/get-phone');
  }

}
