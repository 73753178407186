import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { SearchboxService } from '../../templates/searchbox/searchbox.service';

@Component({
  selector: 'app-flying-from-dialog',
  templateUrl: './flying-combined-direction-dialog.component.html',
  styleUrls: ['./flying-combined-direction-dialog.component.scss'],
  providers: [
    SearchboxService
  ]
})
export class FlyingCombinedDirectionDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('departElement', { static: false }) departElement: ElementRef;
  @ViewChild('destinationElement', { static: false }) destinationElement: ElementRef;
  public isFlyingFrom: boolean;
  public filteredDepartureList: Observable<Array<string>>;
  public filteredDestinationList: Observable<Array<string>>;
  public selectedAirport: string;
  public departureControl: FormControl = new FormControl(null);
  public destinationControl: FormControl = new FormControl(null);
  public activeControl: FormControl = this.departureControl;
  public departureModel: string;
  public destinationModel: string;
  public isMultiCity: string;

  private formDepartureValue: string;
  private formDestinationValue: string;

  constructor(
    private activeModal: NgbActiveModal,
    private searchboxService: SearchboxService
  ) {
  }

  public ngOnInit(): void {
    this.filteredDepartureList = this.initAutocomplete(this.departureControl);
    this.filteredDestinationList = this.initAutocomplete(this.destinationControl);

    this.patchValue();
  }

  public ngAfterViewInit(): void {
    if (this.isFlyingFrom) {
      this.departElement.nativeElement.focus();
      this.activeControl = this.departureControl;
    } else {
      this.destinationElement.nativeElement.focus();
      this.activeControl = this.destinationControl;
    }
  }

  public patchValue(): void {
    setTimeout(() => {
      this.destinationControl.setValue(this.formDestinationValue);
      this.departureControl.setValue(this.formDepartureValue);
    }, 0);
  }

  public initAutocomplete(control: FormControl): any {
    return control.valueChanges
      .pipe(
        debounceTime(200),
        filter(v => !!v),
        switchMap(value => {
            if (_.isNil(value.match(/\D+\(\w+\)/i)) && !_.isEmpty(value)) {
              return this.searchboxService.getLocations(value.trim())
                .pipe(
                  map((response: any[]) => {
                      if (response.length === 0 && !_.isNull(value) && !_.isEmpty(value)) {
                        return Array.of('No cities found');
                      } else {
                        return response;
                      }
                    }
                  ));
            } else {
              return [];
            }
          }
        ));
  }

  public getControlItemsList(): Observable<Array<string>> {
    return this.activeControl === this.departureControl ? this.filteredDepartureList : this.filteredDestinationList;
  }

  public selectAirport(item: string): void {
    this.selectedAirport = item;
    this.activeControl.setValue(item);
    if (!this.destinationControl.value) {
      this.activeControl = this.destinationControl;
      this.destinationElement.nativeElement.focus();
    } else if (!this.departureControl.value) {
      this.activeControl = this.departureControl;
      this.departElement.nativeElement.focus();
    }

    if (this.destinationControl.value && this.departureControl.value) {
      this.saveAndClose();
    }
  }

  public closeDialog(data: { from: string, to: string } = null): void {
    this.activeModal.close(data);
  }

  public saveAndClose(): void {
    this.closeDialog({ from: this.departureControl.value, to: this.destinationControl.value });
  }

  public isDisableSaveButton(): boolean {
    return !(this.departureControl.value && this.destinationControl.value);
  }

  public moveBack(): void {
    this.activeModal.dismiss();
  }
}
