var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, ElementRef, OnInit } from '@angular/core';
import { CallMeDialogComponent } from '../../modal/call-me-dialog/call-me-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import eq from 'lodash-es/eq';
import isNil from 'lodash-es/isNil';
import { environment } from '../../../../environments/environment';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { HeaderService } from './header.service';
import { NavigationStart, Router } from '@angular/router';
import { DataService } from '../../../../shared/shared-data.service';
import { interval } from 'rxjs';
import { cookieEnabled } from '../../../../shared/util/cookie-util';
import { TrackingService } from '../../../services/tracking.service';
import { ComponentBase } from '../ComponentBase';
import { filter, takeWhile } from 'rxjs/operators';
var PHONE_KEY = makeStateKey('phone');
var PHONE_VISIBLE_KEY = makeStateKey('phoneVisible');
var HeaderComponent = /** @class */ (function (_super) {
    __extends(HeaderComponent, _super);
    function HeaderComponent(dialog, appStorage, http, state, headerService, router, dataService, trackingService) {
        var _this = _super.call(this) || this;
        _this.dialog = dialog;
        _this.appStorage = appStorage;
        _this.http = http;
        _this.state = state;
        _this.headerService = headerService;
        _this.router = router;
        _this.dataService = dataService;
        _this.trackingService = trackingService;
        _this.cdnPath = environment.cdnPath;
        return _this;
    }
    HeaderComponent.prototype.ngOnInit = function () {
    };
    HeaderComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.trackingService.getTrack().subscribe(function () {
            _this.setPhone();
            _this.checkTrackCookie();
        });
        this.showCallMeButton();
    };
    HeaderComponent.prototype.setPhoneVisible = function () {
        var _this = this;
        this.headerService.isNight().subscribe(function (response) {
            _this.phoneVisible = !response.data.isNight;
            _this.sendPhoneVisibleData();
            _this.state.set(PHONE_VISIBLE_KEY, _this.phoneVisible);
        });
    };
    HeaderComponent.prototype.openCallMeDialog = function () {
        var _this = this;
        var config = new MatDialogConfig();
        config.position = {
            top: '2%',
        };
        config.data = { phone: this.phone, phoneVisible: this.phoneVisible, isLaunchedFromHeader: true };
        config.panelClass = 'call-me-dialog';
        this.dataService.sendData({ type: 'showModal', data: true });
        var dialogRef = this.dialog.open(CallMeDialogComponent, config);
        dialogRef.afterClosed().subscribe(function (result) {
            _this.dataService.sendData({ type: 'showModal', data: false });
            console.trace('Call me dialog was closed');
        });
    };
    HeaderComponent.prototype.hideMenu = function () {
        this.navMenuCheckbox.nativeElement.checked = false;
        this.dataService.sendData({ type: 'showModal', data: false });
    };
    HeaderComponent.prototype.showMenu = function () {
        if (this.navMenuCheckbox.nativeElement.checked === false) {
            this.dataService.sendData({ type: 'showModal', data: true });
        }
        else {
            this.dataService.sendData({ type: 'showModal', data: false });
        }
    };
    HeaderComponent.prototype.sendPhoneData = function () {
        this.dataService.sendData({ type: 'phone', data: this.phone });
    };
    HeaderComponent.prototype.sendPhoneVisibleData = function () {
        this.dataService.sendData({ type: 'phoneVisible', data: this.phoneVisible });
    };
    HeaderComponent.prototype.showCallMeButton = function () {
        var _this = this;
        this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationStart; }))
            .subscribe(function (event) {
            _this.homePage = eq(event.url, '/home') || eq(event.url, '/');
        });
    };
    HeaderComponent.prototype.checkTrackCookie = function () {
        var _this = this;
        interval(500).pipe(takeWhile(function () {
            _this.trackId = _this.appStorage.getItem('WF_T');
            return !_this.trackId && cookieEnabled();
        })).subscribe(function () { return console.log('TrackId = ' + _this.trackId); }, function () {
        }, function () { return _this.dataService.sendData({ type: 'track', data: true }); });
    };
    HeaderComponent.prototype.setPhone = function () {
        var _this = this;
        var phoneFromStorage = this.appStorage.getItem('WF_PHONE');
        if (phoneFromStorage) {
            this.phone = phoneFromStorage;
            this.sendPhoneData();
            this.state.set(PHONE_KEY, this.phone);
            this.setPhoneVisible();
        }
        else {
            this.headerService.getPhone().subscribe(function (response) {
                if (!isNil(response.data.phone)) {
                    _this.phone = response.data.phone;
                    _this.sendPhoneData();
                    _this.state.set(PHONE_KEY, _this.phone);
                    _this.setPhoneVisible();
                }
            });
        }
    };
    Object.defineProperty(HeaderComponent.prototype, "isBusinessLand", {
        get: function () {
            var businessLanding = this.landingClass === 'First' ? false : true;
            if (!this.firstClassLandingDetected) {
                this.firstClassLandingDetected = !businessLanding;
            }
            return businessLanding && !this.firstClassLandingDetected;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HeaderComponent.prototype, "landingClass", {
        get: function () {
            return isNil(this.router) || isNil(this.router.url) || !this.router.url.includes('first-class') ? 'Business' : 'First';
        },
        enumerable: true,
        configurable: true
    });
    return HeaderComponent;
}(ComponentBase));
export { HeaderComponent };
