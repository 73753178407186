import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-how-it-works-dialog',
  templateUrl: './how-it-works-dialog.component.html',
  styleUrls: ['./how-it-works-dialog.component.scss']
})
export class HowItWorksDialogComponent implements OnInit {
  cdnPath: string;
  constructor(public dialogRef: MatDialogRef<HowItWorksDialogComponent>) {
  }

  ngOnInit() {
      this.cdnPath = environment.cdnPath;
  }

  onCloseDialog(): void {

    this.dialogRef.close();
  }

}
