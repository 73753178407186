var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { DefaultUrlSerializer } from '@angular/router';
var OrderUrlSerializer = /** @class */ (function (_super) {
    __extends(OrderUrlSerializer, _super);
    function OrderUrlSerializer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    OrderUrlSerializer.prototype.parse = function (url) {
        if (url.indexOf('business-class-flights-to-') >= 0) {
            url = url.replace('business-class-flights-to-', 'business-class-flights-to/');
        }
        else if (url.indexOf('business-class-airlines-') >= 0) {
            url = url.replace('business-class-airlines-', 'business-class-airlines/');
        }
        else if (url.indexOf('business-class-') >= 0) {
            url = url.replace('business-class-', 'business-class/');
        }
        else if (url.indexOf('first-class-flights-to-') >= 0) {
            url = url.replace('first-class-flights-to-', 'first-class-flights-to/');
        }
        else if (url.indexOf('first-class-airlines-') >= 0) {
            url = url.replace('first-class-airlines-', 'first-class-airlines/');
        }
        else if (url.indexOf('first-class') >= 0) {
            url = url.replace('first-class-', 'first-class/');
        }
        return _super.prototype.parse.call(this, url);
    };
    OrderUrlSerializer.prototype.serialize = function (tree) {
        var url = _super.prototype.serialize.call(this, tree);
        if (url.indexOf('business-class-flights-to/') >= 0) {
            url = url.replace('business-class-flights-to/', 'business-class-flights-to-');
        }
        else if (url.indexOf('business-class-airlines/') >= 0) {
            url = url.replace('business-class-airlines/', 'business-class-airlines-');
        }
        else if (url.indexOf('business-class/') >= 0) {
            url = url.replace('business-class/', 'business-class-');
        }
        else if (url.indexOf('first-class-flights-to/') >= 0) {
            url = url.replace('first-class-flights-to/', 'first-class-flights-to-');
        }
        else if (url.indexOf('first-class-airlines/') >= 0) {
            url = url.replace('first-class-airlines/', 'first-class-airlines-');
        }
        else if (url.indexOf('first-class/') >= 0) {
            url = url.replace('first-class/', 'first-class-');
        }
        return url;
    };
    return OrderUrlSerializer;
}(DefaultUrlSerializer));
export { OrderUrlSerializer };
