var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Type } from '@angular/core';
import { Subject } from 'rxjs';
import { filter } from "rxjs/operators";
import * as i0 from "@angular/core";
var ApplicationEventBroadcaster = /** @class */ (function (_super) {
    __extends(ApplicationEventBroadcaster, _super);
    function ApplicationEventBroadcaster() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ApplicationEventBroadcaster.prototype.onType = function (messageClass) {
        return this.asObservable().pipe(filter(function (event) { return event instanceof messageClass; }));
    };
    ApplicationEventBroadcaster.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApplicationEventBroadcaster_Factory() { return new ApplicationEventBroadcaster(); }, token: ApplicationEventBroadcaster, providedIn: "root" });
    return ApplicationEventBroadcaster;
}(Subject));
export { ApplicationEventBroadcaster };
