import {Flight} from './flight';
export class QueryFlightParams {
  returnDate: any;
  departureDate: any;
  cabin: any;
  cabinStore: any;
  passengers: number;
  passengersStore: number;
  tripType: any;
  flights: Flight[];

  constructor(cabin?: any,
              passengers?: number,
              tripType?: any) {
    this.cabin = cabin ? cabin : null;
    this.passengers = passengers ? passengers : null;
    this.tripType = tripType ? tripType : null;
  }
}
