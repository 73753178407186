<!-- Modal HIW-->
<div class="hiw-modal animated fadeIn">
  <div id="hiwModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">How it works?</h2>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onCloseDialog()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h3>1. REQUEST A QUOTE</h3>
          <p class="how-item-text">Submit a flight request with your trip details and contact information.</p>
          <h3>2. REVIEW ITINERARIES</h3>
          <p class="how-item-text">Your personal travel concierge will quickly send you the best prices and flight options.</p>
          <h3>3. BOOK & SAVE</h3>
          <p class="how-item-text">Choose your seats, pay securely online, receive e-ticket by email, and save 30%-70%.</p>
        </div>
        <div class="modal-footer">
          <img src="{{cdnPath}}/assets/img/logo.svg">
        </div>
      </div>
    </div>
  </div>
</div>
