<!-- Footer -->
<footer class="py-5 main-block" [ngClass]="{ 'fix-bottom': fixBottom }">
  <div class="container">

    <div class="row">
      <div class="col-6 col-sm-4 col-md-3 nav-footer">
        <span>Cities</span>
        <a routerLink="/business-class-flights-to/amsterdam">Amsterdam</a>
        <a routerLink="/business-class-flights-to/bangkok">Bangkok</a>
        <a routerLink="/business-class-flights-to/barcelona">Barcelona</a>
        <a routerLink="/business-class-flights-to/hong-kong">Hong Kong</a>
        <a routerLink="/business-class-flights-to/london">London</a>
        <a routerLink="/business-class-flights-to/madrid">Madrid</a>
        <a routerLink="/business-class-flights-to/paris">Paris</a>
        <a routerLink="/business-class-flights-to/rome">Rome</a>
        <a routerLink="/business-class-flights-to/shanghai">Shanghai</a>
        <a routerLink="/business-class-flights-to/tokyo">Tokyo</a>
      </div>

      <div class="col-6 col-sm-4 col-md-3 nav-footer">
        <span>Countries</span>
        <a routerLink="/business-class-flights-to/austria">Austria</a>
        <a routerLink="/business-class-flights-to/belgium">Belgium</a>
        <a routerLink="/business-class-flights-to/china">China</a>
        <a routerLink="/business-class-flights-to/france">France </a>
        <a routerLink="/business-class-flights-to/italy">Italy</a>
        <a routerLink="/business-class-flights-to/japan">Japan</a>
        <a routerLink="/business-class-flights-to/netherlands">Netherlands</a>
        <a routerLink="/business-class-flights-to/spain">Spain</a>
        <a routerLink="/business-class-flights-to/thailand">Thailand</a>
        <a routerLink="/business-class-flights-to/united-kingdom">United Kingdom</a>
      </div>

      <div class="col-12 col-sm-4 col-md-3 mt-3 mt-sm-0 nav-footer">
        <span>Company</span>
        <a routerLink="{{ buildLink('flights', isBusinessLand) }}">Flights</a>
        <a href="https://wholesale-flights.zenhotels.com/" target="_blank" rel="noopener noreferrer">Hotels</a>
        <a routerLink="/cruises">Cruises</a>
        <a routerLink="/app-page">App</a>
<!--        <a routerLink="reviews">Reviews</a>-->
        <a routerLink="corporate">Corporate</a>
        <a routerLink="our-team">Meet Our Team</a>
        <a routerLink="about-us">About us</a>
        <a href="https://blog.wholesale-flights.com/">Blog</a>
        <a routerLink="contact-us">Contact Us</a>
      </div>

      <div class="col-12 col-md-3 social-icons">
        <img class="ng-lazyloaded" src="./assets/img/logo-white.svg" alt="WholeSaleFlights.com">
        <a href="http://www.facebook.com/WholesaleTickets" target="_blank" rel="noopener">
          <i class="fa fa-facebook-square fa-2x" aria-hidden="true"></i>
          <span style="text-indent: 100%;white-space: nowrap;overflow: hidden;opacity: 0; width:0;">f</span>
        </a>
        <a href="https://www.twitter.com/wsflights" rel="noopener">
          <i class="fa fa-twitter-square fa-2x" aria-hidden="true"></i>
          <span style="text-indent: 100%;white-space: nowrap;overflow: hidden;opacity: 0;width:0;">t</span>
        </a>
        <a href="http://www.youtube.com/channel/UChxpIgwqmme9ksl7B2sHmQQ" rel="noopener">
          <i class="fa fa-youtube-square fa-2x" aria-hidden="true"></i>
          <span style="text-indent: 100%;white-space: nowrap;overflow: hidden;opacity: 0;width:0;">y</span>
        </a>
        <a href="http://www.linkedin.com/in/wholesaleflights" rel="noopener">
          <i class="fa fa-linkedin fa-2x" ></i>
          <span style="text-indent: 100%;white-space: nowrap;overflow: hidden; opacity: 0;width:0;">l</span>
        </a>
      </div>
   
    </div>

    <div class="col-xs-12 footer-icons">
      <div class="col-sm-12">
        <i class="geo-icon"></i>
        <i class="ssl-icon"></i>
        <i class="mastercard-icon"></i>
        <i class="visa-icon"></i>
        <i class="amex-icon"></i>
        <i class="discover-icon"></i>
        <i class="bbb-icon"></i>
        <i class="iatan-icon"></i>
      </div>
    </div>

    <div class="col-sm-12 footer-copyright">
      <p class="m-0 text-center text-white">
        Copyright © 2007-{{year}} Wholesale-Flights.com - All Rights Reserved. CST
        2088142-40
      </p>
<!--      <p class="m-0 text-center text-white">-->
<!--        Use of this Website constitutes acceptance of the-->
<!--        <a routerLink="terms-and-conditions">Terms of Service</a> and-->
<!--        <a routerLink="privacy-policy">Privacy Policy</a>.-->
<!--      </p>-->
    </div>
  </div>
  <!-- /.container -->
</footer>
