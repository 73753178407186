import { AfterViewInit, Directive, ElementRef, EventEmitter, Inject, Output, PLATFORM_ID } from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

const $ = require('jquery');

@Directive({
  selector: '[ngxPhoneSelect]',
  exportAs: 'ngx-phone-select',
})

export class NgxPhoneSelectDirective implements AfterViewInit {
  private el;

  @Output() countryChangeEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor(el: ElementRef, @Inject(PLATFORM_ID) private platformId: Object) {
    this.el = el;

    const context = this;
    $(this.el.nativeElement).on('countrychange', function(e, countryData) {
      context.countryChangeEvent.emit();
    });
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      $(this.el.nativeElement).intlTelInput({separateDialCode: true});
    }
  }

  setCountry(countryCode: any): void {
    $(this.el.nativeElement).intlTelInput('setCountry', countryCode.toLowerCase());
  }

  getCountryData(): any {
    return $(this.el.nativeElement).intlTelInput('getSelectedCountryData');
  }
}
