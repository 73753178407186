/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./newsletter-form.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/common";
import * as i6 from "./newsletter-form.service";
import * as i7 from "@angular/common/http";
import * as i8 from "@angular/router";
import * as i9 from "./newsletter-form.component";
import * as i10 from "ngx-cookie-service";
var styles_NewsletterFormComponent = [i0.styles];
var RenderType_NewsletterFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NewsletterFormComponent, data: {} });
export { RenderType_NewsletterFormComponent as RenderType_NewsletterFormComponent };
function View_NewsletterFormComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ngb-alert", [["class", "alert"], ["role", "alert"], ["type", "danger"]], [[2, "alert-dismissible", null]], [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = ((_co.successMessage = null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NgbAlert_0, i2.RenderType_NgbAlert)), i1.ɵdid(1, 638976, null, 0, i3.NgbAlert, [i3.NgbAlertConfig, i1.Renderer2, i1.ElementRef], { type: [0, "type"] }, { close: "close" }), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { var currVal_1 = "danger"; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).dismissible; _ck(_v, 0, 0, currVal_0); var currVal_2 = _co.successMessage; _ck(_v, 2, 0, currVal_2); }); }
function View_NewsletterFormComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "col-md-12 col-lg-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Wholesale Secrets Email!"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["First to know about reduced airfares, ticket specials & flight promotions."])), (_l()(), i1.ɵeld(6, 0, null, null, 17, "form", [["class", "form-inline col-md-12 col-lg-6"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i4.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(8, 540672, null, 0, i4.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i4.ControlContainer, null, [i4.FormGroupDirective]), i1.ɵdid(10, 16384, null, 0, i4.NgControlStatusGroup, [[4, i4.ControlContainer]], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 9, "div", [["class", "col-12 col-sm-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 8, "input", [["class", "form-control"], ["formControlName", "email"], ["id", "inputEmail4"], ["placeholder", "Your email address"], ["type", "email"]], [[1, "disabled", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 16).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 16)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 16)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.email = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(14, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(15, { "has-error": 0 }), i1.ɵdid(16, 16384, null, 0, i4.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i4.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DefaultValueAccessor]), i1.ɵdid(18, 671744, null, 0, i4.FormControlName, [[3, i4.ControlContainer], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR], [2, i4.ɵangular_packages_forms_forms_q]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.FormControlName]), i1.ɵdid(20, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), (_l()(), i1.ɵeld(21, 0, null, null, 2, "div", [["class", "col-12 col-sm-4 padding-none"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 1, "button", [["class", "btn"], ["type", "button"]], [[1, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sendHotDeal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(23, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.hotDealsForm; _ck(_v, 8, 0, currVal_7); var currVal_16 = "form-control"; var currVal_17 = _ck(_v, 15, 0, (!_co.hotDealsForm.get("email").valid && !_co.formValid)); _ck(_v, 14, 0, currVal_16, currVal_17); var currVal_18 = "email"; var currVal_19 = _co.email; _ck(_v, 18, 0, currVal_18, currVal_19); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 10).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 10).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 10).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 10).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 10).ngClassValid; var currVal_5 = i1.ɵnov(_v, 10).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 10).ngClassPending; _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = (_co.formSending ? "" : null); var currVal_9 = i1.ɵnov(_v, 20).ngClassUntouched; var currVal_10 = i1.ɵnov(_v, 20).ngClassTouched; var currVal_11 = i1.ɵnov(_v, 20).ngClassPristine; var currVal_12 = i1.ɵnov(_v, 20).ngClassDirty; var currVal_13 = i1.ɵnov(_v, 20).ngClassValid; var currVal_14 = i1.ɵnov(_v, 20).ngClassInvalid; var currVal_15 = i1.ɵnov(_v, 20).ngClassPending; _ck(_v, 12, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); var currVal_20 = (_co.formSending ? "" : null); _ck(_v, 22, 0, currVal_20); var currVal_21 = ((_co.formSending === false) ? "Send Me Deals" : "Loading..."); _ck(_v, 23, 0, currVal_21); }); }
function View_NewsletterFormComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-md-12 col-lg-12 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You\u2019re all set!"]))], null, null); }
function View_NewsletterFormComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "section", [["id", "newsletter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewsletterFormComponent_2)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewsletterFormComponent_3)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewsletterFormComponent_4)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.successMessage; _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.formSent == false); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.formSent == true); _ck(_v, 7, 0, currVal_2); }, null); }
export function View_NewsletterFormComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewsletterFormComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.alreadySignedUp == false); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_NewsletterFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-newsletter-form", [], null, null, null, View_NewsletterFormComponent_0, RenderType_NewsletterFormComponent)), i1.ɵprd(512, null, i6.NewsletterFormService, i6.NewsletterFormService, [i7.HttpClient, i8.Router]), i1.ɵdid(2, 114688, null, 0, i9.NewsletterFormComponent, [i6.NewsletterFormService, i10.CookieService, i4.FormBuilder, i1.ChangeDetectorRef, i1.PLATFORM_ID], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var NewsletterFormComponentNgFactory = i1.ɵccf("app-newsletter-form", i9.NewsletterFormComponent, View_NewsletterFormComponent_Host_0, {}, {}, []);
export { NewsletterFormComponentNgFactory as NewsletterFormComponentNgFactory };
