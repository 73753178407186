import { AfterViewChecked, OnDestroy, OnInit } from '@angular/core';
import { isPlatformBrowser, LocationStrategy } from '@angular/common';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import isEmpty from 'lodash-es/isEmpty';
import eq from 'lodash-es/eq';
import forEach from 'lodash-es/forEach';
import isNil from 'lodash-es/isNil';
import { DataService } from '../shared/shared-data.service';
import { NotifyService } from './services/notify.service';
import { TagService } from './services/tag.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';
import { cookieEnabled } from '../shared/util/cookie-util';
import { Meta } from '@angular/platform-browser';
import { interval, Subscription } from 'rxjs';
import { filter, takeWhile } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BeforeCloseDialogComponent } from './layout/modal/before-close-dialog/before-close-dialog.component';
import { DeviceDetectorService } from 'ngx-device-detector';
var AppComponent = /** @class */ (function () {
    function AppComponent(platformId, router, locStrat, 
    /*private swUpdate: SwUpdate,*/
    dataService, notifyService, tagService, http, meta, modalService, cookieService, deviceService) {
        this.platformId = platformId;
        this.router = router;
        this.locStrat = locStrat;
        this.dataService = dataService;
        this.notifyService = notifyService;
        this.tagService = tagService;
        this.http = http;
        this.meta = meta;
        this.modalService = modalService;
        this.cookieService = cookieService;
        this.deviceService = deviceService;
        this.title = 'app';
        this.isPopState = false;
        this.rendered = false;
        this.scroll = function () {
            var elements = document.getElementsByClassName('navbar-expand');
            var header = elements[0];
            var offsetTop = document.documentElement.scrollTop;
            if (offsetTop > 82) {
                header.classList.add('fixed-header-change-bg');
            }
            else {
                header.classList.remove('fixed-header-change-bg');
            }
        };
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.meta.addTags([{
                property: 'og:image',
                content: 'https://www.wholesale-flights.com/static-assets/images/fb_logo.jpg'
            }, {
                property: 'og:image:width',
                content: '600'
            }, {
                property: 'og:image:height',
                content: '600'
            }]);
        this.cdnPath = environment.cdnPath;
        if (isPlatformBrowser(this.platformId)) {
            /*this.swUpdate.available.subscribe(event => {
              console.log('[App] Update available: current version is', event.current, 'available version is', event.available);
              this.notifyService.warnWithAvailableUpdates({
                text: 'Our site was updated. We\'re strongly recommended to reload page. Otherwise we cannot guarantee stable work.',
                layout: 'bottomRight',
                animation: {
                  open: 'animated fadeInUp',
                  close: 'animated fadeOutDown'
                }
              });
            });*/
            window.document.addEventListener('scroll', this.scroll, true);
            this.locStrat.onPopState(function () {
                _this.isPopState = true;
            });
            this.router.events.subscribe(function (event) {
                // Scroll to top if accessing a page, not via browser history stack
                if (event instanceof NavigationEnd && !_this.isPopState) {
                    window.scrollTo(0, 0);
                    _this.isPopState = false;
                }
                // Ensures that isPopState is reset
                if (event instanceof NavigationEnd) {
                    _this.isPopState = false;
                }
            });
            this.router.events.pipe(filter(function (event) { return event instanceof NavigationStart; })).subscribe(function (event) {
                _this.showConversionCode = !eq(event.url, '/your-request');
            });
            this.subscription = this.dataService.getData()
                .subscribe(function (sharedData) {
                if (eq(sharedData.type, 'showSpinner')) {
                    _this.showSpinner = sharedData.data;
                }
                if (eq(sharedData.type, 'track')) {
                    _this.trackExist = sharedData.data;
                }
                if (eq(sharedData.type, 'showModal')) {
                    if (sharedData.data === true) {
                        _this.hideScroll();
                    }
                    else {
                        _this.showScroll();
                    }
                }
            });
            this.checkCookie();
            interval(50000).pipe(takeWhile(function () {
                if (ga) {
                    ga.getAll().forEach(function (tracker) {
                        _this.clientId = tracker.get('clientId');
                    });
                }
                return !_this.clientId && cookieEnabled();
            })).subscribe(function () { return console.log('ClientId = ' + _this.clientId); }, function () {
            }, function () { return _this.saveClientId(); });
            interval(50000).pipe(takeWhile(function () {
                return !_this.trackExist && cookieEnabled();
            })).subscribe(function () { return console.log('trackExist = ' + _this.trackExist); }, function () {
            }, function () { return _this.saveClientId(); });
        }
    };
    AppComponent.prototype.saveClientId = function () {
        if (!!this.clientId && this.trackExist) {
            this.http.get(environment.serverUrl + '/save/client-id', { params: new HttpParams().set('clientId', this.clientId) }).subscribe(function () {
                console.debug('Save clientId complete');
            });
        }
    };
    AppComponent.prototype.ngOnDestroy = function () {
        if (isPlatformBrowser(this.platformId)) {
            window.document.removeEventListener('scroll', this.scroll, true);
            if (this.subscription) {
                this.subscription.unsubscribe();
            }
        }
    };
    AppComponent.prototype.ngAfterViewChecked = function () {
        if (window['dataLayer']) {
            window['dataLayer'].push({ 'event': 'optimize.activate' });
        }
    };
    AppComponent.prototype.onMouseLeave = function ($event) {
        if ($event.clientY > 15) {
            return;
        }
    };
    AppComponent.prototype.showExitModal = function () {
        var _this = this;
        if (!this.isEmptyLocal(this.cookieService.get('WF_GURU_INSIDER_SENT')) ||
            this.modalService.hasOpenModals() ||
            this.router.url.includes('corporate-private') ||
            this.deviceService.isMobile()) {
            return;
        }
        this.dataService.sendData({ type: 'showModal', data: true });
        var dialogRef = this.modalService.open(BeforeCloseDialogComponent, {
            windowClass: 'before-close-dialog',
            backdropClass: 'hiw-backdrop'
        });
        dialogRef.result.then(function (result) {
            _this.dataService.sendData({ type: 'showModal', data: false });
        }, function (reason) {
            _this.dataService.sendData({ type: 'showModal', data: false });
        });
    };
    AppComponent.prototype.checkCookie = function () {
        this.http.get(environment.serverUrl + '/check-cookie', { params: new HttpParams().set('cookieEnabled', cookieEnabled() ? 'true' : 'false') }).subscribe(function () {
            console.debug('Check cookie complete');
        });
    };
    AppComponent.prototype.hideScroll = function () {
        var scrollSize = window.innerWidth - document.body.scrollWidth;
        var blocks = document.getElementsByClassName('main-block');
        forEach(blocks, function (elem) {
            elem.style.width = 'calc(100% - ' + scrollSize + 'px)';
        });
        document.body.className += ' ' + 'modal-open';
    };
    AppComponent.prototype.showScroll = function () {
        document.body.classList.remove('modal-open');
        document.body.style.paddingRight = '0px';
        var blocks = document.getElementsByClassName('main-block');
        forEach(blocks, function (elem) {
            elem.style.width = '100%';
        });
    };
    AppComponent.prototype.isEmptyLocal = function (fieldValue) {
        return isEmpty(fieldValue) || isNil(fieldValue);
    };
    Object.defineProperty(AppComponent.prototype, "withForm", {
        get: function () {
            var showForm = !this.router.url.includes('faq') &&
                !this.router.url.includes('about-us') &&
                !this.router.url.includes('terms-and-conditions') &&
                !this.router.url.includes('terms-and-conditions-app') &&
                !this.router.url.includes('insurance-terms') &&
                !this.router.url.includes('privacy-policy') &&
                !this.router.url.includes('corporate') &&
                !this.router.url.includes('reviews') &&
                !this.router.url.includes('our-team') &&
                !this.router.url.includes('your-request') &&
                !this.router.url.includes('privacy-app') &&
                !this.router.url.includes('contact-us') &&
                !this.router.url.includes('app-page') &&
                !this.router.url.includes('all-business-class');
            return showForm;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "showDefaultHeader", {
        get: function () {
            return !this.router.url.includes('(s:b)') && !this.router.url.includes('(s:c)');
        },
        enumerable: true,
        configurable: true
    });
    return AppComponent;
}());
export { AppComponent };
