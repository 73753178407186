import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
var WholesaleSecretsService = /** @class */ (function () {
    function WholesaleSecretsService(http, router) {
        this.http = http;
        this.router = router;
    }
    WholesaleSecretsService.prototype.sendEmail = function (email) {
        return this.http.post(environment.serverUrl + '/wholesale_secrets_sign_up', email);
    };
    WholesaleSecretsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WholesaleSecretsService_Factory() { return new WholesaleSecretsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router)); }, token: WholesaleSecretsService, providedIn: "root" });
    return WholesaleSecretsService;
}());
export { WholesaleSecretsService };
