<div class="modal show" tabindex="-1" role="dialog" aria-hidden="true" style="display: block;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <h2 class="modal-title">Congratulations!</h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeDialog()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <i class="icon-support"></i>
                <span class="list-title">We received your request, a travel expert will contact you shortly from a phone number with a 650 area code.</span>
            </div>

            <div class="modal-footer">
                <button type="submit" class="btn" (click)="closeDialog()">Ok</button>
            </div>

        </div>
    </div>
</div>
