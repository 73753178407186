<div class="modal show" tabindex="-1" role="dialog" aria-hidden="true" style="display: block;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <button *ngIf="hasBackButton" type="button" class="btn-back">
                    <i class="icon-back"></i>
                </button>
                <h2 class="modal-title">Flight options</h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="saveAndClose()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <span class="list-title">Trip type</span>
                <ul>
                    <li (click)="selectedTripType = 'Round'"
                        [class.active]="selectedTripType === 'Round'">
                        <a id="round-trip" valtype="round">
                            <span>Round-Trip</span>
                        </a>
                    </li>
                    <li (click)="selectedTripType = 'OneWay'"
                        [class.active]="selectedTripType === 'OneWay'">
                        <a id="one-way" valtype="oneway">
                            <span>One-way</span>
                        </a>
                    </li>
                    <li (click)="selectedTripType = 'MultiCity'"
                        [class.active]="selectedTripType === 'MultiCity'">
                        <a id="multi-city" valtype="multi">
                            <span>Multi-city</span>
                        </a>
                    </li>
                </ul>

                <span class="list-title">Travelers</span>
                <ul>
                    <li *ngFor="let count of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                        (click)="selectedTravelers = count;"
                        [class.active]="count === selectedTravelers">
                        <a><span>{{count}}</span></a>
                    </li>
                </ul>

                <span class="list-title">Cabin type</span>
                <ul>
                    <li (click)="selectedCabin = 'BUSINESS'"
                        [class.active]="selectedCabin === 'BUSINESS'">
                        <a id="BUSINESS">
                            <span>Business</span>
                        </a>
                    </li>
                    <li (click)="selectedCabin = 'FIRST'"
                        [class.active]="selectedCabin === 'FIRST'">
                        <a id="FIRST">
                            <span>First</span>
                        </a>
                    </li>
                    <li (click)="selectedCabin = 'PREMIUMECO'"
                        [class.active]="selectedCabin === 'PREMIUMECO'">
                        <a id="PREMIUMECO">
                            <span>Premium Economy</span>
                        </a>
                    </li>
                    <li (click)="selectedCabin = 'ECONOMY'"
                        [class.active]="selectedCabin === 'ECONOMY'">
                        <a id="ECONOMY">
                            <span>Economy</span>
                        </a>
                    </li>
                </ul>
            </div>

            <div class="modal-footer">
                <button type="submit" (click)="saveAndClose()" class="btn">Save</button>
            </div>

        </div>
    </div>
</div>
