/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./section-three.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./section-three.component";
import * as i3 from "../../modal/call-me-dialog/call-me.service";
var styles_SectionThreeComponent = [i0.styles];
var RenderType_SectionThreeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SectionThreeComponent, data: {} });
export { RenderType_SectionThreeComponent as RenderType_SectionThreeComponent };
export function View_SectionThreeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 37, "section", [["id", "services"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 36, "div", [["class", "container col-xl-12 px-3 py-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "row featurette align-items-center justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "col-sm-12 col-md-12 col-lg-6 col-xl-5 text-center text-lg-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "p", [["class", "featurette-heading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Save On"])), (_l()(), i1.ɵted(-1, null, [" Business Class Flights, Cruises, And Hotels"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [["class", "lead"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Leveraging our strong affiliations with major consolidators and direct access to unpublished rates, private fares, and enticing promotional deals, we can offer the most competitive pricing for international flights, luxury cruises, and premium hotels."])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "a", [["class", "btn btn-primary mb-3 bth-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openCallMeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Get a FREE Quote"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "col-sm-12 col-md-12 col-lg-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "img", [["alt", "Services"], ["aria-hidden", "true"], ["class", "select-box__icon"], ["src", "assets/img/services/flights/01.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 11, "div", [["class", "row featurette pt-5 align-items-center justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 8, "div", [["class", "col-sm-12 col-md-12 col-lg-6 col-xl-5 order-lg-3 text-center text-lg-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 3, "p", [["class", "featurette-heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["How Much Will "])), (_l()(), i1.ɵeld(18, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["I Save?"])), (_l()(), i1.ɵeld(20, 0, null, null, 1, "p", [["class", "lead"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["The price depends on several factors such as your travel destinations, dates, and availability. In most cases, we can reduce costs by 20-50% below retail prices, and occasionally, the savings can be even greater. Request a quote today, and we'll promptly provide you with a quote. "])), (_l()(), i1.ɵeld(22, 0, null, null, 1, "a", [["class", "btn btn-primary mb-3 bth-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openCallMeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Get a FREE Quote"])), (_l()(), i1.ɵeld(24, 0, null, null, 1, "div", [["class", "col-sm-12 col-md-12 col-lg-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 0, "img", [["alt", "Services"], ["aria-hidden", "true"], ["class", "select-box__icon"], ["src", "assets/img/services/flights/02.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 11, "div", [["class", "row featurette pt-5 align-items-center justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 8, "div", [["class", "col-sm-12 col-md-12 col-lg-6 col-xl-5 text-center text-lg-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 3, "p", [["class", "featurette-heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Never Wait On Hold Again "])), (_l()(), i1.ɵeld(30, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["A Dedicated Travel Specialist"])), (_l()(), i1.ɵeld(32, 0, null, null, 1, "p", [["class", "lead"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your personal travel specialist takes care of all the details, from securing preferred seating and catering to specific meal preferences, to arranging travel insurance and ensuring you earn mileage rewards. Essentially, our expertise revolves around finding solutions that save you both time and money! "])), (_l()(), i1.ɵeld(34, 0, null, null, 1, "a", [["class", "btn btn-primary mb-3 bth-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openCallMeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Request a Consultation"])), (_l()(), i1.ɵeld(36, 0, null, null, 1, "div", [["class", "col-sm-12 col-md-12 col-lg-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(37, 0, null, null, 0, "img", [["alt", "Services"], ["aria-hidden", "true"], ["class", "select-box__icon"], ["src", "assets/img/services/flights/03.png"]], null, null, null, null, null))], null, null); }
export function View_SectionThreeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-section-three", [], null, null, null, View_SectionThreeComponent_0, RenderType_SectionThreeComponent)), i1.ɵdid(1, 114688, null, 0, i2.SectionThreeComponent, [i3.CallMeService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SectionThreeComponentNgFactory = i1.ɵccf("app-section-three", i2.SectionThreeComponent, View_SectionThreeComponent_Host_0, {}, {}, []);
export { SectionThreeComponentNgFactory as SectionThreeComponentNgFactory };
